import * as React from 'react';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';

const FoodIcon = ({ onClick = null, className = "" }) => {
    return <DinnerDiningIcon className={`food-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { FoodIcon };
