import { getModuleChildren, scheduleActivity } from './../app/utils';
import { updateFirebaseData } from './../utils/FirebaseBoardUtils';
import { ScheduledActivity } from './../components/activity_generator/ScheduledActivity';
import * as React from 'react';
import { BaseModule, IBaseModule } from './modules/BaseModule';
import { BoardModule, IBoardModuleProps } from './modules/BoardModule/BoardModule';
import { CardModule, ICardModuleProps } from './modules/CardModule/CardModule';
import { NotYetImplementedModule } from './modules/NotYetImplementedModule/NotYetImplementedModule';
import { breakpointTypes, data, getDataPath, getModulesPath, modules as m, modules } from "../utils/constants";
import { CalendarModule } from './modules/CalendarModule/CalendarModule';
import { CalendarEventsModule, ICalendarEventsModule } from './modules/CalendarEventsModule/CalendarEventsModule';
import { ActivityTableModule, IActivityTableModuleProps } from './modules/ActivityTableModule/ActivityTableModule';
import { objectHasProperties } from '../utils/Utils';
import { TodoBoardModule } from './modules/TodoBoardModule/TodoBoardModule';
import { IScheduledActivityCardModuleProps, ScheduledActivityCardModule } from './modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
import { Activity } from '../data/activity/Activity';
import { TData, TModules } from '../app/interface';
import { IReactGridModuleProps, ReactGridModule } from './modules/ReactGridModule/ReactGridModule';
import { deleteData, readData, writeData } from '../utils/Firebase';
import { Layout } from './Layout';
import { addDaysStringDate, getToday } from '../utils/TimeUtils';
import { CalendarData } from '../components/calendar/CalendarData';
import { getTodoModule } from '../app/utils';
import { GridItem } from './modules/ReactGridModule/GridItem';
import { IModuleRelationsProps, ModuleRelations } from './modules/ActivityTableModule/ModuleRelations';
import { TAppData } from '../app/AppContext';

export const useModules = (modulesMock: Partial<TModules>) => {
    const [allModules, setAllModules] = React.useState<{ [key: string]: BaseModule }>(modulesMock?.allModules || {});
    const [moduleFilter, setModuleFilter] = React.useState<string>("");

    const refresh = () => {
        setAllModules({ ...allModules });
    }

    const addModule = (module: BaseModule, setModules: boolean): void => {
        allModules[module.id] = module;
        if (setModules) {
            refresh();
        }
    }

    const deleteModule = (module: BaseModule) => {
        delete allModules[module.id];
        refresh();
    }

    const getModule = async (moduleId: string, dataContext: TData, appContext: TAppData, setModules?: boolean): Promise<BaseModule> => {
        if (moduleId in allModules) {
            return allModules[moduleId]
        }
        else if (appContext.user) {
            const baseModuleObject: IBaseModule = await readData(getModulesPath(appContext.user, moduleId))
            if (baseModuleObject) {
                const module: BaseModule = await getModuleType(baseModuleObject, dataContext, appContext);
                allModules[module.id] = module;
                if (setModules) {
                    refresh();
                }
                return module;
            }
        }
    }

    const getModules = async (moduleIds: string[], dataContext: TData, appContext: TAppData, setModules?: boolean): Promise<BaseModule[]> => {
        const modules = [];
        for (const moduleId of moduleIds) {
            const module = await getModule(moduleId, dataContext, appContext);
            if (module) {
                modules.push(module);
            }
        }
        setModules && refresh();
        return modules;
    }

    const hasModules = (): boolean => objectHasProperties(allModules);

    const getFunctions = () => ({ allModules, addModule, getModule, getModules, refresh, setModuleFilter, deleteModule, hasModules, setAllModules, ...modulesMock })
    return getFunctions();

}

export const getModuleType = async (baseProps: IBaseModule, dataContext: TData, appContext: TAppData, extra?: any): Promise<BaseModule> => {
    let module;

    if (baseProps.type === m.CARD) {
        const moduleOptions = [{
            name: modules.SCHEDULED_ACTIVITY_CARD,
            onClick: ScheduledActivityCardModule.getCreationDialog(baseProps.user, async (newModule: ScheduledActivityCardModule) => {
                newModule.parentModule = this
                // this.addChildModule(newModule, this);
            }),
            testId: 'create-scheduled',
            title: 'Skapa en ny uppgift'
        },
        {
            name: modules.BOARDS,
            onClick: BoardModule.getCreationDialog(baseProps.user, async (newModule: BoardModule) => {
                newModule.parentModule = this
                // this.addChildModule(newModule, this);
            }),
            testId: 'create-board',
            title: 'Skapa en ny lista'
        }]
        const childModules = await getModuleChildren(baseProps, appContext, dataContext)
        const cardProps: ICardModuleProps = {
            containerOnDragStop: (baseModule: BaseModule) => { baseModule.updateFirebase(); },
            containerOnResizeStop: (self: BaseModule) => {
                self.updateFirebase();
            },
            onCheckboxChange: (module: BaseModule) => {
                module.updateFirebase();
            },
            moduleOptions
        }
        const moduleRelations: IModuleRelationsProps = {
            onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
                removedModule.deleteFromFirebase();
            },
            parentModule: null,
            childModules,
            layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
        }
        module = new CardModule(
            { ...baseProps, onDelete: (self: BaseModule) => { console.log("Delete", self) } },
            cardProps,
            moduleRelations
        );
        childModules.forEach((m: any) => m.parentModule = module);
    }
    else if (baseProps.type === m.SCHEDULED_ACTIVITY_CARD) {

        const childModules = await getModuleChildren(baseProps, appContext, dataContext)
        const scheduledActivity = await dataContext.scheduledActivities.getScheduledActivity(baseProps.id)

        console.log(baseProps.name, (baseProps as any).checkboxChecked);
        const cardModuleProps: ICardModuleProps = {
            containerOnDragStop: (baseModule: BaseModule) => { baseModule.updateFirebase(); },
            containerOnResizeStop: (self: BaseModule) => {
                self.updateFirebase();
            },
            checkbox: (baseProps as any).checkbox,
            checkboxChecked: (baseProps as any).checkboxChecked,
            onCheckboxChange: (module: CardModule) => {
                console.log("checked", module.checkboxChecked)
                module.updateFirebase();
                if(module instanceof ScheduledActivityCardModule){
                    module.scheduledActivity.finish();
                    dataContext.scheduledActivities.updateScheduledActivity(module.scheduledActivity, dataContext);
                    module.scheduledActivity.updateFirebase(appContext.user);
                    
                    if(module.parentModule instanceof TodoBoardModule){
                        module.parentModule.hideCheckedModules();
                        // module.parentModule.forceRender();
                    }
                }
                // const cardModule = (module as CardModule).parentModule as BoardModule
                // const parentModule: CardModule = cardModule.parentModule as CardModule;
                // const parentModule: BoardModule = (module as CardModule).parentModule as BoardModule

                // const checkedIds = parentModule.getCheckedModuleIds();
                // module.parentModule.filterModules && parentModule.filterModules(checkedIds);
                // module.updateFirebase();
            }
        }
        const scheduledActivityCardModuleProps: IScheduledActivityCardModuleProps = {
            scheduledActivity,
            onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule) => {
                scheduleActivity(dataContext, appContext, updatedScheduledActivity.scheduledActivity, appContext.user);
            },
        }

        const relationProps: IModuleRelationsProps = {
            onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
                if ((createdModule as any).scheduledActivity) {
                    dataContext.scheduledActivities.addScheduledActivity((createdModule as any).scheduledActivity);
                    (createdModule as any).scheduledActivity.updateFirebase(appContext.user)
                    dataContext.modules.addModule(createdModule, true);
                }
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
                removedModule.deleteFromFirebase();
            },
            parentModule: null,
            childModules: childModules,
            layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
        }

        module = new ScheduledActivityCardModule(
            baseProps,
            cardModuleProps,
            scheduledActivityCardModuleProps,
            relationProps
        )
        module.childModules.forEach(m => { m.parentModule = module });
    }
    else if (baseProps.type === m.CALENDAR) {
        module = new CalendarModule(baseProps);
    }
    else if (baseProps.type === m.CALENDAR_EVENTS) {

        const startDate = getToday()
        const endDate = addDaysStringDate(startDate, 10)
        const dates = await dataContext.calendarData.getDates(startDate, endDate, true, false);
        const scheduledActivityIds: string[] = await dataContext.calendarData.getScheduledActivitiesInTimespan(startDate, endDate);
        const scheduledActivityCardModules: ScheduledActivityCardModule[] = await dataContext.modules.getModules(scheduledActivityIds, dataContext, appContext, true) as ScheduledActivityCardModule[];
        const calendarEventsProps: ICalendarEventsModule = {
            dates,
            startDate,
            endDate,
            activities: dataContext.activities.activities,
            updateCalendarData: (calendarData: CalendarData) => {
                calendarData.updateFirebase(appContext.user)
            },
            onDeleteScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {

                dataContext.calendarData.deleteScheduledActivity(calendarData.date, scheduledActivity.id);
                dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity.scheduledActivity);
                scheduledActivity.scheduledActivity.deleteFirebase(appContext.user);

                if (scheduledActivity.parentModule instanceof CalendarEventsModule) {
                    scheduledActivity.parentModule.childModules = scheduledActivity.parentModule.childModules.filter(m => m.id !== scheduledActivity.id);
                }

                calendarData.updateFirebase(appContext.user);

            },
            onTemporaryScheduledActivityCreated: (newTemporaryScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {
                newTemporaryScheduledActivity.updateFirebase();
                newTemporaryScheduledActivity.scheduledActivity.updateFirebase(appContext.user);
                calendarData.updateFirebase(appContext.user);
            },
            onScheduledActivityUpdate: (scheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData, newCalendarData?: CalendarData) => {
                newCalendarData && dataContext.calendarData.setCalendarDate(newCalendarData, false);
                newCalendarData && newCalendarData.updateFirebase(appContext.user);
                dataContext.calendarData.setCalendarDate(calendarData, true);
                calendarData.updateFirebase(appContext.user);

                dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity.scheduledActivity, dataContext);
                scheduledActivity.scheduledActivity.updateFirebase(appContext.user);
            },
            onScheduledActivityClick: (activity: Activity) => { console.log("Scheduled Activity Click", activity) },
            onFinishScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => {
                dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity.scheduledActivity, dataContext);
                scheduledActivity.scheduledActivity.updateFirebase(appContext.user);
                if (newScheduledActivity) {
                    const calendarData: CalendarData = await dataContext.calendarData.getCalendarDate(newScheduledActivity.scheduledActivity.getStartTimeDate(), true, true);
                    calendarData.schedule.push(newScheduledActivity.id);
                    newScheduledActivity.scheduledActivity.addScheduledActivity(dataContext, calendarData, appContext.user);
                }
            }
        }

        const relationProps: IModuleRelationsProps = {
            onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
                if ((createdModule as any).scheduledActivity) {
                    dataContext.scheduledActivities.addScheduledActivity((createdModule as any).scheduledActivity);
                    (createdModule as any).scheduledActivity.updateFirebase(appContext.user)
                    dataContext.modules.addModule(createdModule, true);
                }
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                // removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
                removedModule.deleteFromFirebase();
                if(yourself instanceof CalendarEventsModule){
                    const calendarData = yourself.dates.find(c => c.schedule.includes(removedModule.id))
                    if(calendarData){
                        calendarData.schedule = calendarData.schedule.filter(s => s !== removedModule.id);
                    }
                    calendarData.updateFirebase(appContext.user);
                    console.log(yourself.dates);
                }
            },
            parentModule: null,
            childModules: scheduledActivityCardModules,
            layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
        }
        // console.log(datesData);
        module = new CalendarEventsModule(
            baseProps,
            relationProps,
            calendarEventsProps,
        )
        scheduledActivityCardModules.forEach(m => { m.parentModule = module });
    }
    else if (baseProps.type === m.GRID_LAYOUT) {
        const relationProps: IModuleRelationsProps = {
            parentModule: null,
            childModules: [],
            layout: (baseProps as any).layout || null,
            onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, parent?: ModuleRelations) => {
                removedModule.deleteFromFirebase();
                parent.forceRender();
                parent.updateFirebase();
            },
        }
        const reactGridModuleProps: IReactGridModuleProps = {
            onDragStart: (newGrid: GridItem) => { /* console.log("Drag Start", newGrid) */ },
            onDragStop: (reactGridModule: ReactGridModule) => {
                reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, reactGridModule.currentBreakpoint);
                // dataContext.modules.getModule(baseProps.id, dataContext, appContext.user, false).then((module: BaseModule) => {
                //     newGrid.forEach((l: GridItem) => {
                //         (module as ReactGridModule).layout.updateGridItem(l);
                //         writeData(`${getModulesPath(appContext.user, (module as ReactGridModule).layout.id)}/layout/sm/${l.i}`, l)
                //         writeData(`${getModulesPath(appContext.user, (module as ReactGridModule).layout.id)}/layout/lg/${l.i}`, l)
                //     })
                // })
            },
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            onResizeStop: (reactGridModule: ReactGridModule) => {
                reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, reactGridModule.currentBreakpoint);
            },
            currentBreakpoint: appContext.currentBreakpoint
            // layout: (baseProps as IBaseModule & { layout: ILayout })?.layout,
            // onRemoveModule: (module: BaseModule, reactGridModule: ReactGridModule) => { 
            //     console.log("Remove Module", module, this);
            //     reactGridModule.updateFirebase()
            // },
        }
        module = new ReactGridModule(
            baseProps,
            relationProps,
            reactGridModuleProps);
    }
    else if (baseProps.type === m.ACTIVITY_TABLE) {
        // const activities: {[key: string]: IActivity } = await readData(getDataPath(appContext.user, data.ACTIVITY));
        // const acts: Activity[] = Object.values(activities).map((act: IActivity) => new Activity(act))
        // console.log(acts);
        // dataContext.activities.setAllActivities(acts);
        const activityTableModuleProps: IActivityTableModuleProps = {
            onCreateScheduledActivity: (scheduledActivity: ScheduledActivity) => {
                scheduleActivity(dataContext, appContext, scheduledActivity, appContext.user);
            },
            onUpdateActivity: (activity: Activity) => { dataContext.activities.updateActivity(appContext.user, activity, true) },
            onDeleteScheduledActivity: (scheduledActivity: ScheduledActivity) => { console.log("Delete", scheduledActivity) },
            onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => { console.log("Finish", scheduledActivity) },
            onDeleteActivity: (activity: Activity) => { dataContext.activities.deleteActivity(activity, true) },
            onCreateActivity: async (activity: Activity) => {
                dataContext.activities.addActivity(activity, true)
            },
            sendToTodoList: async (activity: Activity) => {
                const todoBoardModule: TodoBoardModule = await getTodoModule(dataContext, appContext)
                todoBoardModule.createBoardItem(activity.name, activity.id);
            },
            rowData: [],
            startDate: getToday(),
            endDate: addDaysStringDate(getToday(), 10),

        }
        module = new ActivityTableModule(
            baseProps,
            activityTableModuleProps,
        );
    }
    else if (baseProps.type === m.BOARDS) {
        const childModules = await getModuleChildren(baseProps, appContext, dataContext);
        console.log(childModules);
        const relationProps: IModuleRelationsProps = {
            parentModule: null,
            childModules,
            layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
            onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                removedModule.deleteFromFirebase();
                yourself.updateFirebase();
                yourself.forceRender();
                // removedModule.parentModule.layout.updateFirebaseData(appContext.user);
                // removedModule.deleteFromFirebase();
            },
        }
        const boardModuleProps: IReactGridModuleProps = {
            onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
            onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop") },
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            onResizeStop: (reactGridModule: ReactGridModule) => {
                reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
            },
            currentBreakpoint: appContext.currentBreakpoint
        }
        module = new BoardModule(baseProps, relationProps, boardModuleProps);
        childModules.forEach((m: any) => { m.parentModule = module });
    }
    else if (baseProps.type === m.TODO_BOARD) {
        const childModules = await getModuleChildren(baseProps, appContext, dataContext)
        const relationProps: IModuleRelationsProps = {
            parentModule: null,
            childModules,
            layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
            onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
                dataContext.scheduledActivities.addScheduledActivity((createdModule as ScheduledActivityCardModule).scheduledActivity);
                writeData(
                    `${getDataPath(appContext.user, data.SCHEDULED_ACTIVITY, createdModule.id)}`,
                    (createdModule as ScheduledActivityCardModule).scheduledActivity
                )
                createdModule.updateFirebase();
                yourSelf.updateFirebase();
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                const scheduled = (removedModule as ScheduledActivityCardModule).scheduledActivity;
                dataContext.scheduledActivities.deleteScheduledActivity(scheduled);
                removedModule.deleteFromFirebase();
                deleteData(`${getModulesPath(appContext.user, yourself.id)}/layout/sm/${removedModule.id}`);
                deleteData(`${getModulesPath(appContext.user, yourself.id)}/layout/lg/${removedModule.id}`);
                deleteData(`${getDataPath(appContext.user, data.SCHEDULED_ACTIVITY, removedModule.id)}`);
            },
        }
        const reactGridModuleProps: IReactGridModuleProps = {
            onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
            onDragStop: (reactGridModule: ReactGridModule) => {
                reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
            },
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            onResizeStop: (reactGridModule: ReactGridModule) => {
                reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
            },
            currentBreakpoint: appContext.currentBreakpoint,
        }

        const boardProps: IBoardModuleProps = {
            hideChecked: (baseProps as any).hideChecked,
        }

        baseProps.user = appContext.user;
        module = new TodoBoardModule(
            baseProps,
            relationProps,
            reactGridModuleProps,
            boardProps
        ) as TodoBoardModule;
        module.childModules.forEach(m => { m.parentModule = module });
    }
    else {
        module = new NotYetImplementedModule({ ...baseProps });
    }

    module.user = appContext.user;
    return module;
}

// export const getCreationDialog = (type: string): JSX.Element => {
//     if(type === modules.CARD){
//         console.log(type);
//         return CardModule.getCreationDialog();
//     }
// }