import * as React from 'react';
import { Calendar } from '../../../components/calendar/Calendar';
import { BaseModule, IBaseModule } from '../BaseModule';
import { breakpointTypes, getModulesPath, GridHeightsSM, GridWidthsSM, ILayouts } from '../../../utils/constants';
import { writeData } from '../../../utils/Firebase';
import { GridItem } from '../ReactGridModule/GridItem';

export class CalendarModule extends BaseModule {
 
    constructor(props: IBaseModule) {
        super(props);
        this.autoPackingDimensions = { w: 24, h: 30 };
    }

    updateModuleFirebase(){
        const firebaseData: Omit<IBaseModule, "onDelete"> = {id: this.id, user: this.user, type: this.type, name: this.name}
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }


    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    renderTopMenu(): JSX.Element {
        return null;
    }
    customDelete(): void {
        return null;
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }, this.id)
        }
    }

    render(): JSX.Element {
        return <Calendar  />
    }

    toFirebaseObject() {
        
    }

    updateFirebase(){

    }

}