import * as React from 'react';
import { IBaseModule } from "../BaseModule";
import { ActivityStatus, breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import { writeData } from '../../../utils/Firebase';
import { CalendarData } from '../../../components/calendar/CalendarData';
import { Activity } from '../../../data/activity/Activity';
import { Column } from '../../../components/layout/column/Column';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { Button } from '../../../components/button/Button';
import {  getNow, getToday, Millis, convertTimestampToStringDate } from '../../../utils/TimeUtils';
import StringIcons, { IconTypes } from '../../../components/string_icons/StringIcons';
import { ILog } from '../../../components/calendar/Calendar';
import './Upcoming.scss'
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { TData } from '../../../app/interface';
import { generateId } from '../../../utils/Utils';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { TextArea } from '../../../components/textarea/TextArea';
import { Input } from '../../../components/input/Input';
import { GridItem } from '../ReactGridModule/GridItem';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { TAppData } from '../../../app/AppContext';
import { IModuleRelationsProps, ModuleRelations } from '../ActivityTableModule/ModuleRelations';

export interface ICalendarEventsModule {
    startDate: string;
    endDate: string;
    dates: CalendarData[],
    updateCalendarData: (calendarData: CalendarData) => void;
    onScheduledActivityClick: (activity: Activity) => void;
    onTemporaryScheduledActivityCreated: (newScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => void,
    onScheduledActivityUpdate: (scheduledActivity: ScheduledActivityCardModule, oldCalendarDate: CalendarData, newCalendarDate?: CalendarData) => void;
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivityCardModule, calendarDate: CalendarData) => void;
    onFinishScheduledActivity: (finishedScheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => void;
    activities: Activity[];
}

export class CalendarEventsModule extends ModuleRelations implements ICalendarEventsModule {
    startDate: string;
    endDate: string;
    dates: CalendarData[];
    onScheduledActivityUpdate: (scheduledActivity: ScheduledActivityCardModule, oldCalendarDate: CalendarData, newCalendarDate?: CalendarData) => void;
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivityCardModule, oldcalendarDate: CalendarData) => void;
    activities: Activity[];
    updateCalendarData: (calendarData: CalendarData) => void;
    onTemporaryScheduledActivityCreated: (newScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => void;
    onScheduledActivityClick: (activity: Activity) => void;
    onFinishScheduledActivity: (finishedScheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => void;

    constructor(
        props: IBaseModule,
        moduleRelationsProps: IModuleRelationsProps,
        calendarEventsModuleProps: ICalendarEventsModule
    ) {
        super(props, moduleRelationsProps);
        this.autoPackingDimensions = { w: GridWidthsLG.QUARTER, h: GridHeightsLG.HALF };
        Object.assign(this, calendarEventsModuleProps);
        this.dataContextArgs = ["activities.activities", "calendarData.calendarData"]
    }

    onDataContextUpdate = async (dataContext: TData, appContext: TAppData): Promise<void> => {
        if (!this.noDataContextSync && dataContext) {
            this.activities = dataContext.activities.activities;
            // this.dates = await CalendarEventsModule.getDatesData(dataContext, appContext);
            // console.log(this.dates);
            this.forceRender();
        }
    }

    renderTopMenu(): JSX.Element {
        return null;
    }

    updateModuleFirebase() {
        const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }


    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    deleteScheduledActivityFromDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule = calendarData?.schedule.filter(s => s !== scheduledActivity.id);
    }

    addScheduledActivityToDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule?.push(scheduledActivity.id);
    }

    updateScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, oldDate: string, previousDate: string) => {
        const newDate = convertTimestampToStringDate(scheduledActivity.scheduledActivity.startTime);
        if (newDate !== previousDate) {
            const oldCalendarD = this.dates.find(d => d.date === oldDate)
            const newCalendarD = this.dates.find(d => d.date === newDate)
            this.deleteScheduledActivityFromDate(oldCalendarD, scheduledActivity);
            this.addScheduledActivityToDate(newCalendarD, scheduledActivity);
            this.onScheduledActivityUpdate(scheduledActivity, oldCalendarD, newCalendarD);
        }
        else {
            const oldCalendarD = this.dates.find(d => d.date === oldDate)
            this.onScheduledActivityUpdate(scheduledActivity, oldCalendarD);
        }
        this.forceRender();
    }

    renderContent = (schedule: ScheduledActivityCardModule[], cd: CalendarData) => {
        schedule.map(s => s.scheduledActivity).sort((a, b) => {
            if (a?.isFinished() && !b?.isFinished()) {
                return -1;
            }
            else if (!a?.isFinished() && b?.isFinished()) {
                return 1;
            }
            else if (a?.isFinished() && b?.isFinished()) {
                if (a.startTime && !b.startTime) {
                    return 1;
                }
                else if (!a.startTime && b.startTime) {
                    return -1;
                }
                else if (a.startTime && b.startTime) {
                    return a.startTime < b.startTime ? -1 : 1
                }
            }
            else if (!a?.isFinished() && !b?.isFinished()) {
                return a?.startTime < b?.startTime ? -1 : 1;
            }
        })

        return <>
            {
                schedule.map((scheduledActivity: ScheduledActivityCardModule, key) => {
                    if (!scheduledActivity) {
                        return null;
                    }
                    return <React.Fragment key={key}>{scheduledActivity.renderModule()}</React.Fragment>
                    // return <div
                    //     onClick={() => {
                    //         this.toggleModal(
                    //             <ScheduledActivityViewer
                    //                 defaultDate={!scheduledActivity.scheduledActivity.startTime && new Date(convertStringDateToTimestamp(cd.date))}
                    //                 scheduledActivity={scheduledActivity.scheduledActivity}
                    //                 onDeleteScheduledActivity={() => {
                    //                     const calendarD = this.dates.find(d => d.calendarData.date === cd.date)
                    //                     if (calendarD?.schedule) {
                    //                         this.deleteScheduledActivityFromDate(calendarD, scheduledActivity);
                    //                         this.onDeleteScheduledActivity(scheduledActivity, calendarD.calendarData);
                    //                         this.closeModal();
                    //                     }
                    //                 }}
                    //                 onUpdateScheduledActivity={(scheduledActivity: ScheduledActivityCardModule, previousDate: string) => {
                    //                     this.updateScheduledActivity(scheduledActivity, cd.date, previousDate);
                    //                     this.closeModal();
                    //                 }}
                    //             />
                    //             // <div>
                    //             //     <DateTimepicker
                    //             //         separateTimeAndDate
                    //             //         defaultTime={scheduledActivity.startTime ? new Date(scheduledActivity.startTime) : new Date(convertStringDateToTimestamp(cd.date))}
                    //             //         onChange={(date: Date) => {
                    //             //             scheduledActivity.startTime = date.getTime();
                    //             //         }} />
                    //             //     <Button
                    //             //         label="Bekräfta"
                    //             //         onClick={() => {
                    //             //             // console.log(convertTimestampToStringDateAndTime(scheduledActivity.startTime));
                    //             //             this.onScheduledActivityUpdate(scheduledActivity);
                    //             //             this.closeModal();
                    //             //         }}
                    //             //     />
                    //             // </div>
                    //         )
                    //         // calendarEventModule.onScheduledActivityClick(scheduledActivity, cd.date, dataContext)
                    //     }}
                    //     key={key}
                    //     data-testid="upcoming-calendar-event-row-content"
                    //     className={`upcoming-calendar-event-row${scheduledActivity?.scheduledActivity?.isFinished() ? " finished" : ""} ${cd.date}`}>
                    //     {scheduledActivity?.scheduledActivity?.content}
                    //     {scheduledActivity?.scheduledActivity.isFinished() ? <><CheckIcon />{scheduledActivity.scheduledActivity.getTotalRunningTimeFormatted()}</> : null}
                    //     {scheduledActivity.scheduledActivity.stopTime && " " + covertTimestampToStringTime(scheduledActivity.scheduledActivity.stopTime)}
                    //     <div data-testid="activity-starttime" className="activity-starttime">{!scheduledActivity.scheduledActivity.isFinished() && scheduledActivity.scheduledActivity.startTime ? " " + covertTimestampToStringTime(scheduledActivity.scheduledActivity.startTime) : " - Ingen tid satt ännu"}</div>
                    //     {/* {scheduledActivity.timed && !scheduledActivity.isFinished() && renderPlayPauseStopButtons(scheduledActivity)} */}
                    //     {!scheduledActivity.scheduledActivity.timed && !scheduledActivity.scheduledActivity.isFinished() && <Checkbox
                    //         testId="finish-box"
                    //         checked={scheduledActivity.scheduledActivity.isFinished()}
                    //         onChange={(checked, l, e) => {
                    //             e.stopPropagation();
                    //             if (checked) {
                    //                 // finish(scheduledActivity)
                    //                 const newScheduledActivity: ScheduledActivity = scheduledActivity.scheduledActivity.finish();
                    //                 const newScheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
                    //                     { id: newScheduledActivity.id, name: newScheduledActivity.content, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user },
                    //                     undefined,
                    //                     { parentModule: this, onRemoveChildModule: () => { } }
                    //                 )
                    //                 this.onFinishScheduledActivity(scheduledActivity, newScheduledActivityCardModule);
                    //                 // this.onScheduledActivityUpdate(scheduledActivity);
                    //                 // onScheduledActivityUpdate(scheduledActivity);
                    //             }
                    //         }} />}
                    // </div>
                })
            }

            {cd.data?.logs?.length > 0 && cd.data?.logs.map((log: ILog, key) => {
                return <div onClick={() => {
                    // setNewText(log.content as string);
                    // setModalData({ calendarData: cd, log, index: key })
                    // setShowModalType("log");
                    this.toggleModal(<div>{log.content}</div>)
                }} key={key}>- {log.title}</div>
            })}
        </>
    }

    addScheduledActivity = (newScheduledActivity: ScheduledActivityCardModule, cd: CalendarData) => {
        cd.schedule.push(newScheduledActivity.id);
        this.childModules.push(newScheduledActivity);

        this.onTemporaryScheduledActivityCreated(newScheduledActivity, cd);
        this.closeModal();
        this.forceRender();
    }

    getRows = () => {
        const today = getToday();
        // const dates = await calendarData.getDates(calendarEventModule.startDate, calendarEventModule.endDate, true, false);
        let i = 0;
        const t = [];
        let allExpiredActivities = [];
        for (const calendarData of this.dates) {
            const isToday = calendarData.date === today;
            // const schedule = await cd.getSchedule(dataContext);
            // if(isToday){
            //     console.log(schedule);
            // }
            const activitiesExpiredMoraThanHoursAgo = calendarData.schedule.filter(s => {
                if (!s) {
                    return false;
                }
                const scheduledActivityCardModule: ScheduledActivityCardModule = this.childModules.find(m => m.id === s) as ScheduledActivityCardModule;
                const timeSinceStart = scheduledActivityCardModule?.scheduledActivity?.startTime + (Millis.hour * 3);
                const now = getNow();
                const isFinished = scheduledActivityCardModule?.scheduledActivity?.isFinished()
                return timeSinceStart < now && !isFinished
            });
            allExpiredActivities = allExpiredActivities.concat(activitiesExpiredMoraThanHoursAgo);
            const icons = calendarData.data?.icons;
            const nCompletedTasks = calendarData.schedule.filter(s => (this.childModules.find(m => m.id === s) as ScheduledActivityCardModule)?.scheduledActivity?.status === ActivityStatus.DONE)?.length;
            const totalTasks = calendarData.schedule.length;
            const schedule = calendarData.schedule.map(s => this.childModules.find(m => m.id === s) as ScheduledActivityCardModule);
            t.push(
                <React.Fragment key={i}>
                    <Column className="upcoming-calendar-events-row" dataTestid="upcoming-calendar-event-row">
                        <div
                            data-testid="upcoming-calendar-event-header"
                            onClick={() => {
                                this.toggleModal(<Column alignLeft>
                                    <h1>{calendarData.date}</h1>
                                    <Button label={'Lägg till en befintlig aktivitet'} onClick={() => {
                                        this.toggleModal(<ActivityPicker activities={this.activities} onSelect={(activity: Activity) => {
                                            // const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), content: activity.name, status: ActivityStatus.NOT_STARTED, activityId: activity.id, interval: activity.interval });
                                            const newScheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
                                                { id: generateId(), name: activity.name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user },
                                                undefined,
                                                { parentModule: this, onRemoveChildModule: () => { } }
                                            )
                                            this.addScheduledActivity(newScheduledActivityCardModule, calendarData);
                                            this.onScheduledActivityUpdate(newScheduledActivityCardModule, calendarData);
                                        }} />)
                                    }} />
                                    <MarginBottom />
                                    <Button label={'Lägg till en temporär aktivitet'} onClick={() => {
                                        this.toggleModal(
                                            <InputConfirm
                                                label='Namn på aktiviteten'
                                                onConfirm={name => {
                                                    // const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), content: name, status: ActivityStatus.NOT_STARTED, startTime: 0 });
                                                    const newScheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
                                                        { id: generateId(), name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user },
                                                        undefined,
                                                        { parentModule: this, onRemoveChildModule: () => { } }
                                                    )
                                                    this.addScheduledActivity(newScheduledActivityCardModule, calendarData);
                                                }
                                                }
                                            />)
                                    }} />
                                    <MarginBottom />
                                    <Button
                                        label="Skapa ett dagboksinlägg"
                                        onClick={() => {
                                            let title = "";
                                            let text = "";
                                            this.toggleModal(<div>
                                                <Input onChange={t => {
                                                    title = t;
                                                }} />
                                                <MarginBottom />
                                                <TextArea
                                                    rows={10}
                                                    cols={50}
                                                    onChange={txt => {
                                                        if (!calendarData?.data) {
                                                            calendarData.data = { logs: [], icons: [] }
                                                        }
                                                        if (!calendarData?.data?.logs) {
                                                            calendarData.data.logs = [];
                                                        }
                                                        text = txt
                                                    }}
                                                />
                                                <Button label="Skapa" onClick={() => {
                                                    calendarData?.data?.logs?.push({ id: generateId(), content: text, created: getNow(), title })
                                                    this.updateCalendarData(calendarData)
                                                    this.closeModal();
                                                }} />
                                                {calendarData?.data?.logs?.map((log, key) => {
                                                    return <div key={key}>
                                                        <div>{log.title}</div>
                                                        <div>{log.content}</div>
                                                    </div>
                                                })
                                                }
                                            </div>
                                            )
                                        }}
                                    />
                                </Column>)
                            }}
                            className={`upcoming-calendar-event-header${isToday ? " today" : ""}`}
                        >
                            <div className="margin-right">
                                {calendarData.date} {nCompletedTasks + "/" + totalTasks}
                            </div>
                            {icons?.length > 0 && icons.map((icon: IconTypes, key: number) => {
                                return <div key={key}>
                                    {StringIcons(icon as IconTypes, () => { })}
                                </div>
                            })}
                        </div>
                        {this.renderContent(schedule, calendarData)}
                    </Column>
                </React.Fragment>
            )
            i++;
        }
        // setExpiredActivities(allExpiredActivities);
        // .then(data => {
        return t;
        // })
    }

    render(): JSX.Element {
        const rows = this.getRows();
        return <div data-testid="upcoming-events" className="upcoming-events">
            {rows}
        </div>
    }

    toFirebaseObject() {

    }

    updateFirebase() {

    }

    // static getDatesData = async (dataContext: TData, appContext: TAppData): Promise<TDates> => {
    //     const startDate = getToday();
    //     const endDate = addDaysStringDate(startDate, 10);
    //     const dates = await dataContext.calendarData.getDates(startDate, endDate, true, false);
    //     const datesData: TDates = []
    //     for (const date of dates) {
    //         const scheduledActivities: ScheduledActivity[] = await date.getSchedule(dataContext);
    //         const scheduledActivityIds: string[] = scheduledActivities.map(s => s.id);

    //         const schedule: ScheduledActivityCardModule[] = await dataContext.modules.getModules(scheduledActivityIds, dataContext, appContext) as ScheduledActivityCardModule[]
    //         const dd = { calendarData: date, schedule }
    //         datesData.push(dd);
    //     }
    //     return datesData;
    // }

}