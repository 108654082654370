import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { EditIcon } from '../../icons/EditIcon';

const ExpansionPanel = ({ className = "", summary, details, defaultExpanded = undefined, onClick = null }) => {
    return (
        <Accordion className={className} defaultExpanded={defaultExpanded} onClick={() => { if (onClick) { onClick() } }}>
            <AccordionSummary
                expandIcon={<EditIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails>
                {details}
            </AccordionDetails>
        </Accordion>)
};

export { ExpansionPanel };
