import { createContext } from "react";

export interface IHoliday {
    ['arbetsfri dag']: string;
    ['dag i vecka']: string;
    datum: string;
    flaggdag: string;
    helgdag: string;
    namnsdag: string[];
    ['röd dag']: string;
    vecka: string;
    veckodag: string;
}

export interface IHolidays {
    dagar: IHoliday[];
}

interface ICalendar {
    data: any;
    setDate: Function;
    setData: Function;
    selectedDate: string;
    setSelectedDate: Function;
    activeIcons: string[];
    setActiveIcons: Function;
    weatherForecast: any;
    // actionMenuState: { name: string, checked: boolean }[];
    // holidays: IHoliday[];
}

const CalendarContext = createContext<ICalendar>({
    data: [],
    setDate: (date) => { },
    setData: (data) => { },
    selectedDate: "",
    setSelectedDate: (date) => { },
    activeIcons: [],
    setActiveIcons: (icons) => { },
    weatherForecast: {},
    // actionMenuState: []
    // holidays: []
});

export default CalendarContext;