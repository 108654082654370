import * as React from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const ExclamationIcon = ({ onClick = null, className }) => {
    return <PriorityHighIcon className={`exclamation-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { ExclamationIcon };
