import { Button } from '@mui/material';
import * as React from 'react';

interface IButton {
    label: string;
    onClick: any;
    disabled?: boolean;
    className?: string
    dataTestid?: string;
    size?: "small" | "medium" | "large";
}

const Btn = (props: IButton) => {
    const { onClick, disabled, label, className, dataTestid } = props
    return <Button role="button" size={props.size} className={className} data-testid={dataTestid} onClick={onClick} disabled={disabled} variant="contained" color="primary">{label}</Button>
}

export { Btn as Button }