import * as React from 'react'
import { useEffect, useState } from 'react'
import { getMonth, getYear, eachDayOfInterval, startOfToday, startOfMonth, lastDayOfMonth, subMonths, addMonths, subYears, addYears } from 'date-fns'
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { writeData } from '../../utils/Firebase';
import { CalendarMonth } from './CalendarMonth'
import "./Calendar.scss";
import { firebaseModulePaths, getMonthName } from '../../utils/constants';
import { getCalendarDatesData } from '../../utils/FirebaseCalendarUtils';
import { IconTypes } from '../string_icons/StringIcons';
import CalendarContext, { IHolidays } from './CalendarContext';
import { Row } from '../layout/row/Row';
import { AppContext } from '../../app/AppContext';
import { Activity } from '../../data/activity/Activity';

export interface ILog {
    id: string;
    created: number;
    title?: string;
    content: string | JSX.Element;

}

export interface ICalendarEvent {
    created: number;
    title?: string;
    content: string | JSX.Element;
    date?: string;
    finished: boolean;
    icons?: IconTypes[];
    type: string;
    extra?: any;
}

export interface ICalendarDate {
    timeReport?: string;
    purchases?: { merchandiseId: string, time: number }[];
    logs: ILog[];
    icons: IconTypes[];
}

export interface IDate {
    date: string;
    data: ICalendarDate;
    schedule?: Activity[];
}

export interface ICalendar {
    id: string;
    name: string;
    dates: any
}

export const getDefaultCalendarData = () => ({ notes: [], events: [], icons: [] })

const Calendar = ({ startDate = startOfToday() }) => {
    const { user} = React.useContext(AppContext);
    const [data, setData] = useState<Array<IDate>>();
    const [today, setToday] = useState(startDate)
    const [thisMonthsDates, setDisplayedDates] = useState<Array<Date>>([])
    const [selectedDate, setSelectedDate] = useState("");
    const [activeIcons, setActiveIcons] = useState<string[]>([]);
    const [weatherForecast, setWeatherForecast] = useState({})
    // const [holidays, setHolidays] = useState<IHoliday[]>()
    const [showTidRapport, setShowTidRapport] = useState(false);

    const testGetWeatherForecast = async (starttime = "08", endtime = "22") => {
        const longitude = 18.158;
        const latitude = 59.5812;
        const date = "2022-05-07";
        const time = "12:00:00"
        const validTime = `${date}T${time}Z`
        const url = `https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/${longitude}/lat/${latitude}/data.json`
        const test = await fetch(url)
        const json = await test.json();
        // console.log(json);
        const getBetween8and22 = json.timeSeries.filter(t => {
            const substring = t.validTime.substring(
                t.validTime.indexOf("T") + 1,
                t.validTime.lastIndexOf(":")
            ).split(":")[0]
            return substring >= starttime && substring <= endtime
        })
        json.timeSeries = getBetween8and22;
        setWeatherForecast(json);
    }

    /**
     * 
     * @param year "2022"
     * @param month "05"
     */
    const getHolidays = async (year, month): Promise<IHolidays> => {
        const url = `https://sholiday.faboul.se/dagar/v2.1/${year}/${month}`
        try {
            const holidays = await fetch(url);
            const json: IHolidays = await holidays.json();
            return json;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    useEffect(() => {
        const init = async () => {
            // await testGetWeatherForecast();
            const getData = async () => {
                const calendarDatesData = await getCalendarDatesData(user);
                setData(calendarDatesData);
            }

            await getData();
            const generatedDates = eachDayOfInterval({
                start: startOfMonth(today),
                end: lastDayOfMonth(today)
            })
            setDisplayedDates([...generatedDates]);
        }

        init();
    }, [today, user])


    const setDate = (calendarDate: IDate) => {
        if (data && calendarDate?.date) {
            const index = data.findIndex(d => d.date === calendarDate.date);
            if (index < 0) {
                data.push(calendarDate);
            }
            else {
                data[index] = calendarDate;
            }
            writeData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, calendarDate.date), calendarDate)
            setData([...data])
        }
    }

    const renderMonthArrows = () => <Row className="calendar-arrows margin-bottom">
        <ArrowBack className="arrow-back" onClick={() => {
            const newMonth = subMonths(today, 1).getMonth();
            if (newMonth === 11) {
                const newYear = subYears(today, 1).getFullYear();
                //@ts-ignore
                today.setYear(newYear);
            }
            today.setMonth(newMonth);
            const copiedDate = new Date(today.getTime());
            setToday(copiedDate);
        }} />
        <ArrowForward className="arrow-forward" onClick={() => {
            const newMonth = addMonths(today, 1).getMonth();
            if (newMonth === 0) {
                const newYear = addYears(today, 1).getFullYear();
                //@ts-ignore
                today.setYear(newYear);
            }
            today.setMonth(newMonth);
            const copiedDate = new Date(today.getTime());
            setToday(copiedDate);
        }} />
    </Row>

    const renderMonthAndYear = () => <div className="calendar-header margin-bottom">{getMonthName(getMonth(today))} - {getYear(today)}</div>

    const renderCalendar = () => {
        // const workHours = getTotalHoursWorkedSoFar()
        return <div data-testid="calendar" className="calendar">
            {data && <CalendarContext.Provider value={{ data, setData, setDate, selectedDate, setSelectedDate, activeIcons, setActiveIcons, weatherForecast }}>
                {<Row className="center-content">{renderMonthAndYear()}{renderMonthArrows()}</Row>}
                {<CalendarMonth displayedDates={thisMonthsDates} today={today} />}
            </CalendarContext.Provider>}
        </div>
    }

    return (
        <>
            {renderCalendar()}
        </>
    )
}

export { Calendar }
