import * as React from 'react';
import { BaseModule } from '../module/modules/BaseModule';
import { Column } from '../components/layout/column/Column';
import { MarginBottom } from '../components/layout/margin/MarginBottom';

const IsolatedEnvironment = ({ testModules, width = "600px", testButton, runCode }: { testModules: BaseModule[], width?: string, testButton?: boolean, runCode?: () => void }) => {

    React.useEffect(() => {
        runCode && runCode()
    }, [])

    if (!testModules) {
        return <div>Module is missing</div>
    }

    return (<div style={{ width }}>
        {testButton && <Column alignLeft>
            <MarginBottom />
        </Column>
        }

        {testModules.map(mod => {
            return <React.Fragment key={mod.id}>{mod.renderModule(undefined, undefined, true)}<MarginBottom /></React.Fragment>
        })}
    </div>);
};

export { IsolatedEnvironment };