import * as React from 'react';
import { ArrowForward } from '@mui/icons-material';

const ArrowForwardIcon = ({ onClick = null, className = "" }) => {
    return <ArrowForward className={`arrow-forward-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { ArrowForwardIcon };
