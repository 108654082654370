import * as React from 'react';
import { CloseOutlined } from '@mui/icons-material';

const CloseIcon = ({ onClick = null, className = "", dataTestid = undefined }) => {
    return <CloseOutlined 
    style={{zIndex: 100}} data-testid={dataTestid || "close-icon"} className={`close-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { CloseIcon };