import * as React from 'react';
import HamburgerIcon from '@mui/icons-material/Menu';

const MenuIcon = ({ onClick = null, className = "" }) => {
    return <HamburgerIcon className={`menu-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { MenuIcon };
