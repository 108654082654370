import * as React from 'react';
import { ChildCare } from '@mui/icons-material';

const KidsIcon = ({ onClick = null, className = "" }) => {
    return <ChildCare className={`kids-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { KidsIcon };
