import * as React from 'react';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const ChecklistIcon = ({ onClick = null, className = "", style = null }) => {
    return <FactCheckIcon style={style} className={`checklist-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { ChecklistIcon };
