import { IEventLog } from '../components/calendar/CalendarData';
import * as React from 'react'
import { ITag } from '../components/tags/Tags';
import { readData, writeData } from '../utils/Firebase';
import { LogTypeEnums, firebaseModulePaths } from '../utils/constants';
import { addDaysStringDate, getToday, subDaysStringDate } from '../utils/TimeUtils';
import { TData } from '../app/interface';

export const useTags = (user) => {
    const [tags, setTags] = React.useState<{ [key: string]: ITag }>({});


    // const addTag = (tag: ITag) => {
    //     tags[tag.id] = tag;
    //     setTags({ ...tags })
    //     writeData(firebaseModulePaths.GET_TAGS_MODULE_PATH(user), tags)
    // }

    // const removeTag = (tag: ITag) => {
    //     delete tags[tag.id]
    //     setTags({ ...tags })
    //     writeData(firebaseModulePaths.GET_TAGS_MODULE_PATH(user), tags)
    // }

    // const getTag = async (tagId: string): Promise<ITag> => {
    //     if (tagId in tags) {
    //         return tags[tagId];
    //     }
    //     const tagsData = await readData(firebaseModulePaths.GET_TAGS_MODULE_PATH(user, tagId))
    //     return tagsData;
    // }

    const getTagsByName = (tagName: string): ITag[] => {
        const tagsByName = Object.values(tags).filter(t => t.name === tagName)
        return tagsByName;
    }

    const getPrioedTags = async (dataContext: TData): Promise<{name: string, occurrences: number}[]> => {
        const logs: IEventLog[] = await dataContext.calendarData.getEventLogs(subDaysStringDate(getToday(), 30), addDaysStringDate(getToday(), 30));
        const tagLogs = logs.filter(log => log.type === LogTypeEnums.TAG);
        const tagOccurrences = tagLogs.reduce((acc, log) => {
            if(!acc.find(t => t.name === log.content)) {
                acc.push({name: log.content, occurrences: 1})
            } else {
                acc.find(t => t.name === log.content).occurrences += 1;
            }
            return acc;
        }, []);
        return tagOccurrences.sort((a, b) => b.occurrences - a.occurrences);
    }

    return { tags, setTags,/*  addTag, removeTag, getTag, */ getTagsByName, getPrioedTags };
}