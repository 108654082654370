import * as React from 'react';
import {Sick} from '@mui/icons-material';

const SickIcon = ({ onClick = null, className = "" }) => {
    return <Sick className={`sick-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { SickIcon };
