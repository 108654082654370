import * as React from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const PlayIcon = ({ onClick = null, className = "" }) => {
    return <PlayCircleOutlineIcon className={`play-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { PlayIcon };
