import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as TP } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';

interface ITimePicker {
    onChange: (date: Date) => void;
    defaultTime?: number;
}

const TimePicker = ({ onChange, defaultTime }: ITimePicker) => {
    const [value, setValue] = React.useState<Date | null>(defaultTime ? new Date(defaultTime) : null);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TP
                className='time-picker'
                ampm={false}
                label="Välj en tid"
                onChange={(newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
                value={value} />
        </LocalizationProvider>
    );
}
export { TimePicker }