import * as React from 'react';
import { TableRows } from '@mui/icons-material';

const TableIcon = ({ onClick = null, className = "" }) => {
    return <TableRows className={`table-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { TableIcon };
