import * as React from 'react';
import { BaseModule } from '../BaseModule';
import { GridHeightsSM, ILayouts } from '../../../utils/constants';
import { GridItem } from '../ReactGridModule/GridItem';

export class NotYetImplementedModule extends BaseModule {

    updateModuleFirebase(): void {

    }
    renderTopMenu(): JSX.Element {
        return <div></div>
    }
    customDelete(): void {

    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            sm: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                h: GridHeightsSM.FULL,
                w: 24
            }, this.id),
            lg: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                h: GridHeightsSM.FULL,
                w: 24
            }, this.id)
        }
    }

    render(): JSX.Element {
        return <div>Not yet implemented module {this.type}</div>
    }

    toFirebaseObject() {

    }

    updateFirebase() {

    }

}