import * as React from 'react';
import PauseIcon from '@mui/icons-material/Pause';

const PausIcon = ({ onClick = null, className = "" }) => {
    return <PauseIcon className={`paus-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { PausIcon };
