import * as React from 'react'
import { addOrDeleteFromArray } from '../../../utils/Utils';
import { Button } from '../../button/Button';
import StringIcons, { IconTypes } from '../../string_icons/StringIcons';
import "./IconPicker.scss"

const IconPicker = ({ initialIcons = [], onSelect, multiChoice = false }) => {
    // const [open, setOpen] = React.useState(false);
    const [selectedIcons, setSelectedIcons] = React.useState<string[]>(initialIcons);

    return (
        <div>
            {/* <Button label={'Välj ikon'} onClick={() => setOpen(!open)} /> */}
            {/* <Modal showModal={open} setShowModal={setOpen}> */}
            <div>{Object.values(IconTypes).map((iconType, idx) => {
                const onC = () => {
                    if (!multiChoice) {
                        onSelect(iconType);
                        // setOpen(false);
                    }
                    else if (multiChoice) {
                        setSelectedIcons(addOrDeleteFromArray(selectedIcons, iconType));
                    }
                }
                const isSelected = selectedIcons.includes(iconType) ? "selected-icon" : "";
                const Icon = StringIcons(iconType, onC, isSelected);
                return <React.Fragment key={idx}>{Icon}</React.Fragment>
            })}
            </div>
            {multiChoice && <Button label="Klar" onClick={() => {
                onSelect(selectedIcons);
                // setOpen(false);
            }} />}
            {/* </Modal> */}
        </div>
    )
}

export { IconPicker };