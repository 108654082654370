import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import Main from './Main'
import { AppContext } from "./AppContext"
import { DataContext } from './DataContext';
import { TopBar } from './TopBar';
import { SubBar } from './SubBar';
import { useContainerDimensions } from '../components/calendar/useContainerDimensions';
import { TData } from './interface';

export interface IAppProps {
  appContext: any;
  dataContext: TData;
}

const App = (props: IAppProps) => {
  const { appContext, dataContext } = props;
  const componentRef = React.useRef(null)
  const mobileBreakpoint = 796;
  const { width, height } = useContainerDimensions(componentRef)
  const isMobile = width < mobileBreakpoint
  appContext.width = width;
  appContext.isMobile = isMobile;

  return (
    <div className="main-responsive" ref={componentRef} data-testid="main">
      <div className='main'>
        <BrowserRouter>
          <AppContext.Provider value={appContext}>
            <DataContext.Provider value={dataContext}>
              <TopBar />
              <SubBar />
              <Main />
            </DataContext.Provider>
          </AppContext.Provider>
        </BrowserRouter>
      </div>
    </div>
  )
}

export default App
