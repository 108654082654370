import * as React from 'react';
import { BoardModule, IBoardModuleProps } from '../BoardModule/BoardModule';
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { IScheduledActivityCardModuleProps, ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { ActivityStatus, breakpointTypes, modules } from '../../../utils/constants';
import { generateId } from '../../../utils/Utils';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Column } from '../../../components/layout/column/Column';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { Activity } from '../../../data/activity/Activity';
import { TData } from '../../../app/interface';
import { BaseModule, IBaseModule } from '../BaseModule';
import { IModuleRelationsProps, ModuleRelations } from '../ActivityTableModule/ModuleRelations';
import { IReactGridModuleProps, ReactGridModule } from '../ReactGridModule/ReactGridModule';
import { GridItem } from '../ReactGridModule/GridItem';
import { Checkbox } from '../../../components/checkbox/Checkbox';

export class TodoBoardModule extends BoardModule {
    activities: Activity[];
    dataTestid = "todo-board-module";
    dataContextArgs?: string[];

    constructor(
        baseProps: IBaseModule,
        relationProps: IModuleRelationsProps,
        reactGridProps: IReactGridModuleProps,
        boardProps?: IBoardModuleProps,
        activities?: Activity[]
    ) {
        super(baseProps, relationProps, reactGridProps, boardProps);
        this.dataContextArgs = ["activities.activities", "modules.allModules"]
        this.activities = activities || [];
    }

    async onDataContextUpdate(dataContext: TData) {
        this.activities = dataContext?.activities?.activities || [];
    }

    getCustomCardFront = (card: CardModule) => {
        // return <CustomCardFront todoBoardModule={this} card={card} />
    }

    createScheduledActivity = (id: string, content: string, status: ActivityStatus, activityId?: string, startTime?: number) => new ScheduledActivity({ id, content, status, activityId, startTime });

    createCardModule(baseProps, scheduledActivity: ScheduledActivity): CardModule | ScheduledActivityCardModule {
        const cardModuleProps: ICardModuleProps = {
            containerOnDragStop: (baseModule: BaseModule) => { },
            containerOnResizeStop: (self: BaseModule) => {
                // self.updateFirebase();
            }
            // childModules: [],
            // parentModule: this
        }
        const scheduledActivityCardModuleProps: IScheduledActivityCardModuleProps = {
            scheduledActivity,
            onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule) => {
                // this.onUpdateScheduledActivity(updatedScheduledActivity);
            },
            // onDelete: async (cardModule: ScheduledActivityCardModule) => {
            //     this.removeModule(cardModule.id);
            // }
        }
        const relationProps: IModuleRelationsProps = {
            parentModule: this,
            childModules: [],
            layout: null,
            onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
                console.log("onCreateChildModule", createdModule, yourSelf)
            },
            onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
                console.log("remove", removedModule.name);
                this.onRemoveChildModule(removedModule, yourself);
                // removedModule.parentModule.layout.updateFirebaseData(user);
                // removedModule.deleteFromFirebase();
            },
        }
        return new ScheduledActivityCardModule(
            baseProps,
            cardModuleProps,
            scheduledActivityCardModuleProps,
            relationProps
        )
    }

    createBoardItem = (name: string, activityId?: string) => {
        const baseProps = { id: generateId(), name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user }
        const scheduledActivity = this.createScheduledActivity(baseProps.id, baseProps.name, ActivityStatus.NOT_STARTED, activityId)
        const scheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
            baseProps,
            undefined,
            {
                parentModule: this,
                onRemoveChildModule: () => {
                    console.log('hej')
                }
            },
            scheduledActivity
        );
        this.addChildModule(scheduledActivityCardModule, this);
        this.renderModule();
        this.closeModal();
    }

    hideCheckedModules = () => {
        const test = this.getCheckedModuleIds();
        this.filterModules(test)
    }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                this.createBoardItem(name);
                            }} />
                        )
                    }}>
                    Lägg till en temporär aktivitet
                    <PlusIcon />
                </div>
                <div
                    data-testid='add-existing-activity-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <ActivityPicker testId="activity-picker" activities={this.activities} onSelect={(activity: Activity) => this.createBoardItem(activity.name, activity.id)} />
                        )
                    }}>
                    Lägg till befintlig aktivitet
                    <PlusIcon />
                </div>
                <div>
                    <Checkbox label='Dölj klara aktiviteter' checked={this.hideChecked} onChange={checked => {
                        this.hideChecked = checked;
                        this.updateFirebase();
                        this.hideCheckedModules();
                    }} />
                </div>
            </Row>
        </Column>
    }

    static createTodoBoardModule = (
        baseProps?: Partial<IBaseModule>,
        customRelationProps?: Partial<IModuleRelationsProps>,
        customBoardModuleProps?: Partial<IReactGridModuleProps>,
        activities?: Activity[],
    ): TodoBoardModule => {
        const boardModuleProps: IReactGridModuleProps = {
            onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
            onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop", reactGridModule) },
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            onResizeStop: (reactGridModule: ReactGridModule) => {
                // reactGridModule.layout.updateFirebaseData(baseProps.user, reactGridModule.id);
            },
            ...customBoardModuleProps,
            currentBreakpoint: breakpointTypes.LG
        }
        const relationProps: IModuleRelationsProps = ModuleRelations.getDefaultModuleRelationsProps(customRelationProps)
        const todoBoardModule = new TodoBoardModule(
            BaseModule.getDefaultBaseModuleProps("TodoBoardModule", modules.TODO_BOARD, baseProps),
            ModuleRelations.getDefaultModuleRelationsProps(relationProps),
            boardModuleProps,
            undefined,
            activities
        );
        return todoBoardModule
    }

}