import { GymActivity } from './GymActivity';
import { useState, useEffect } from "react";
import { data, firebaseModulePaths, getDataPath } from "../../utils/constants";
import { getAverageValueFromArray } from "../../utils/Calc";
import { deleteData, readData, writeData } from "../../utils/Firebase";
import { Activity, getDefaultActivity } from "./Activity";
import { getNow } from '../../utils/TimeUtils';
import { asyncMap } from '../../utils/Utils';
import { TActivities } from '../../app/interface';

const getActivities = (ids: string[], customActivities): Activity[] => {
    if (!customActivities?.length) {
        return [];
    }
    const filteredActivities: Activity[] = [];
    for (const a of customActivities) {
        if (ids?.includes(a.id)) {
            filteredActivities.push(a);
        }
    }
    return filteredActivities;
}

export const convertFirebaseActivities = (acts): Activity[] => {
    if (acts) {
        const mappedActivities: Activity[] = Object.values(acts).map((act, idx) => {
            //@ts-ignore
            const activity: Activity = act.isGym ? new GymActivity(act) : new Activity(act);
            return activity;
        })

        mappedActivities.forEach((activity: Activity) => {
            activity.parentActivities = getActivities(activity.parentActivities as unknown as string[], mappedActivities);
            activity.childActivities = getActivities(activity.childActivities as unknown as string[], mappedActivities);
            activity.rootActivities = getActivities(activity.rootActivities as unknown as string[], mappedActivities);
            activity.chainedActivities = getActivities(activity.chainedActivities as unknown as string[], mappedActivities);
            activity.leafActivities = getActivities(activity.leafActivities as unknown as string[], mappedActivities);
        })
        return mappedActivities;
    }
}

const useActivities = (user): TActivities => {
    const activitiesPath = firebaseModulePaths.GET_ACTIVITY_MODULE_PATH(user);
    const [activities, setActivities] = useState<Activity[]>([]);

    useEffect(() => {
        if (user && !activities?.length) {
            init();
        }
    }, [user])

    const setAllActivities = (acts: Activity[]) => {
        setActivities(acts);
        // console.log(activities);
    }

    const init = async () => {
        const acts: Activity[] = await readData(activitiesPath);
        const mappedActivities = convertFirebaseActivities(acts);
        if (!activities?.length && mappedActivities?.length) {
            setAllActivities(mappedActivities || []);
        }
    }

    const deleteActivity = (activity: Activity, updateFirebase?: boolean): Activity[] => {
        const newActivities = activities.filter((a: Activity) => a.id !== activity.id);
        rerenderActivities();
        if (updateFirebase) {
            deleteData(getDataPath(user, data.ACTIVITY, activity.id));
        }
        return newActivities;
    }

    const addActivity = (activity: Activity, updateFirebase?: boolean) => {
        setActivities([...activities, activity]);
        rerenderActivities();
        if (updateFirebase) {
            writeData(getDataPath(user, data.ACTIVITY, activity.id), activity.toJSON())
        }
        return activity;
    }

    const getActivity = (id: string): Activity => {
        const foundActivity: Activity = activities?.find((activity: Activity) => activity.id === id);
        return foundActivity;
    }

    const getRootActivities = (): Activity[] => {
        return activities.filter(a => a.isRoot())
    }

    const getSiblingActivities = (activity: Activity, excludeSelf: boolean = false): Activity[] => {
        const sibblings: Activity[] = [];

        if (activity.isRoot()) {
            return activities.filter(a => (a?.isRoot() && !(excludeSelf && a.id === activity.id)))
        }

        activity.parentActivities.forEach((parent: Activity) => {
            parent.childActivities.forEach((child: Activity) => {
                if (!sibblings.includes(child) && !(excludeSelf && child.id === activity.id)) {
                    sibblings.push(child);
                }
            })
        })
        return sibblings
    }

    const getPreviousSibbling = (activity: Activity): Activity => {
        const sibblings: Activity[] = getSiblingActivities(activity);
        if (!sibblings) {
            return null;
        }
        const indexOfACtivity = sibblings.findIndex(s => s.id === activity.id);
        if (indexOfACtivity === 0) {
            return null;
        }
        return sibblings[indexOfACtivity - 1]
    }

    const getNextSibbling = (activity: Activity): Activity => {
        const sibblings: Activity[] = getSiblingActivities(activity);
        if (!sibblings) {
            return null;
        }
        const indexOfACtivity = sibblings.findIndex(s => s.id === activity.id);
        if (indexOfACtivity === sibblings.length - 1) {
            return null;
        }
        return sibblings[indexOfACtivity + 1]
    }

    

    const rerenderActivities = () => {
        setAllActivities([...activities])
    }

    const updateActivity = (user, activity: Activity, updateFirebase) => {
        const i = activities.findIndex(a => a.id === activity.id);
        activities[i] = activity;
        rerenderActivities();
        if (updateFirebase) {
            activity.updateFirebase(user)
        }
    }

    

    const getAveragePointsAllActivities = (): number => {
        if (!activities?.length) {
            return 0;
        }
        const average: number = Math.round(getAverageValueFromArray(activities, x => x.fixedBonusPoints ? x.fixedBonusPoints : x.averageTime > 0 ? x.averageTime / 60000 : 75))
        return average;
    }

    const getExpiredActivitiesSorted = async (dataContext, startDate: string, endDate: string, expired: boolean = true): Promise<Activity[]> => {
        const t = await asyncMap([...activities], async s => {
            const test = await s.getSelectedActivity(dataContext, startDate, endDate);
            return { dueTime: test?.dueTime, activity: s }
        })
        const acts = t.sort((a, b) => {
            if (a.dueTime && b.dueTime) {
                return a.dueTime < b.dueTime ? -1 : 1
            }
            else if (a.dueTime && !b.dueTime) {
                return -1;
            }
            else if (!a.dueTime && b.dueTime) {
                return 1;
            }
            else return -1;
        });
        if (expired) {
            const now = getNow();
            return acts.filter(a => a.dueTime < now).map(a => a.activity)
        }
        return acts.map(a => a.activity);
    }

    const createActivity = (name: string, updateFirebase: boolean): Activity => {
        const activity: Activity = getDefaultActivity(name);
        addActivity(activity, updateFirebase);
        return activity;
    }

    return { activities, setAllActivities, deleteActivity, getSiblingActivities, getPreviousSibbling, getNextSibbling, getActivity, rerenderActivities, updateActivity, addActivity, getAveragePointsAllActivities, getExpiredActivitiesSorted, createActivity };

}

export { useActivities };