import { writeData } from '../utils/Firebase';
import { firebaseSettingsPath } from '../utils/constants';
import { readData } from '../utils/Firebase';
import { useState } from 'react';
import { useEffect } from 'react'

// export interface ISettings {
//     [key: string]: ISetting;
// }

export interface ISetting {
    [key: string]: any;
}


const useSettings = (user) => {
    const [settings, setSettings] = useState<ISetting>({})
    useEffect(() => {
        if (user) {
            initSettings();
        }
    }, [user])

    const initSettings = async () => {
        const path = firebaseSettingsPath(user);
        const settings = await readData(path);
        setSettings(settings || {})
    }

    const getSetting = async (settingId: string): Promise<string> => {
        if (!settings || settingId in settings === false) {
            const setting = await readData(firebaseSettingsPath(user, settingId));
            if (setting) {
                settings[settingId] = setting;
                return setting;
            }
            else return null;
        }
        return settings[settingId];
    }

    const setSetting = (key, value) => {
        const newSettings = { ...settings, [key]: value };
        setSettings(newSettings);
        writeData(firebaseSettingsPath(user), newSettings);
    }

    const updateFirebase = async (settingId: string, setting: ISetting) => {
        await writeData(firebaseSettingsPath(user, settingId), setting)
    }

    return { settings, getSetting, setSetting, updateFirebase }

}

export { useSettings }
