import * as React from "react";
import { useContext, useRef } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import "./Main.scss";
import { AppContext } from "./AppContext";
import { useContainerDimensions } from "../components/calendar/useContainerDimensions";
import { Home } from "../pages/home/Home";
import { PageLoader } from "../components/page/page_loader/PageLoader";

const Main = () => {
  const {user, setMainWidth} = useContext(AppContext);
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  React.useEffect(() => {
    setMainWidth(width);
  }, [width])

  return (
    <div className="content" data-testid="content" ref={componentRef}>
      <Routes>
        <Route path="/" element={<Navigate to="/Home" replace />} />
        {user && <Route path={"/Home"} element={<PageLoader />} />}
        {!user && <Route path={"/Home"} element={<Home />} />}
      </Routes>
    </div>
  );
};


export default Main;
