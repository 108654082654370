import * as React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';

const ReloadIcon = ({ onClick = null, className = "" }) => {
    return <ReplayIcon className={`reload-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { ReloadIcon };
