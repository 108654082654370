import * as React from 'react';
import { Add } from '@mui/icons-material';

const PlusIcon = ({ onClick = null, className = "", color = "" }) => {
    return <Add style={{ color }} className={`plus-icon${className ? " " + className : ""}`} onClick={(e) => {
        if (onClick) { onClick(e) }
    }} />
};

export { PlusIcon };
