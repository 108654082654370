import { createContext } from "react";
import { breakpointTypes } from "../utils/constants";

export interface TAppData {
    user: string,
    setUser: (user) => void,
    rootLayoutId: string,
    setRootLayoutId: (rootLayoutId) => void,
    isMobile?: boolean,
    width?: number,
    mainWidth: number,
    setMainWidth: (mainWidth) => void,
    currentBreakpoint: breakpointTypes,
    setCurrentBreakpoint: (currentBreaktpoint) => void,
    // firebaseSession: {},
    lockedModules: false,
    setLockedModules: (lockedModules: boolean) => void,
    grids: {},
    setGrids: (grids) => void,
    forceUpdateLayout: "",
    setForceUpdateLayout: (layoutid: string) => void,
    dragged: { draggedItemid: "", draggedLayoutid: "", draggedGandParentLayoutId: "" },
    setDragged: (dragged: string) => void,
    itemsAreContainerDroppable: false,
    setItemsAreContainerDroppable: (droppable) => void,
    mock: boolean;
}

const AppContext = createContext<TAppData>({
    user: undefined,
    setUser: (user) => { },
    rootLayoutId: "",
    setRootLayoutId: (rootLayoutId) => { },
    isMobile: false,
    width: 0,
    mainWidth: 0,
    setMainWidth: (mainWidth) => { },
    currentBreakpoint: breakpointTypes.SM,
    setCurrentBreakpoint: (currentBreaktpoint) => { },
    lockedModules: false,
    setLockedModules: (lockedModules: boolean) => { },
    grids: {},
    setGrids: (grids) => { },
    forceUpdateLayout: "",
    setForceUpdateLayout: (layoutid: string) => { },
    dragged: { draggedItemid: "", draggedLayoutid: "", draggedGandParentLayoutId: "" },
    setDragged: (dragged) => { },
    itemsAreContainerDroppable: false,
    setItemsAreContainerDroppable: (droppable) => {},
    mock: false
});

export { AppContext };