import { convertTimestampToStringObject, getStringDatesBetween } from './TimeUtils';
import { readData } from '../utils/Firebase';
import { ICalendar } from './../components/calendar/Calendar';
import { firebaseModulePaths } from "./constants";
import { generateId } from "../utils/Utils"
import { writeData } from './Firebase';
import { addYears, subYears } from 'date-fns';
import { ICalendarData } from '../components/calendar/CalendarData';

export const createCalendar = async (user: string, calendarName: string, pageType, deletePaths, componentPath = "") => {
    const id = generateId();
    const path = componentPath || firebaseModulePaths.GET_CALENDAR_MODULE_PATH(user);
    const calendarData: ICalendar = { id, name: calendarName, dates: [] }
    writeData(path, calendarData);
}

export const getCalendarData = async (user) => {
    const calendarData = await readData(firebaseModulePaths.GET_CALENDAR_MODULE_PATH(user))
    return calendarData;
}

export const getCalendarDates = async (user) => {
    const calendarDates = await readData(firebaseModulePaths.GET_CALENDAR_DATES_MODULE_PATH(user))
    return calendarDates;
}

export const getCalendarDateData = async (user, date: string) => {
    const calendarDateData = await readData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, date))
    return calendarDateData;
}

export const setDateData = async (user, date: string, data) => {
    const calendarDateData = await getCalendarDateData(user, date);
    const newData = { ...calendarDateData, ...data };
    await writeData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, date), newData)
}

export const forceSetDateData = async (user, date: string, data) => {
    await writeData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, date), data)
}

export const getCalendarDatesData = async (user) => {
    const calendarDates = await getCalendarDates(user);
    if (!calendarDates) {
        return [];
    }
    const flatCalendarDates = [];
    for (const year of Object.values(calendarDates)) {
        for (const month of Object.values(year)) {
            for (const date of Object.values(month)) {
                flatCalendarDates.push(date);
            }
        }
    }
    return flatCalendarDates;
}

export const getDatesDataForMonth = async (user: string, year, month) => {
    const path = firebaseModulePaths.GET_CALENDAR_MONTH_MODULE_PATH(user, year, month);
    const data = await readData(path)
    return data
}

export const getDatesDataForYear = async (user: string, year) => {
    const path = firebaseModulePaths.GET_CALENDAR_YEAR_MODULE_PATH(user, year);
    const data = await readData(path);
    return data
}

export const getDatesBetween = async (user: string, from: string, to: string) => {
    const dates = [];
    const stringDates = getStringDatesBetween(from, to);
    for (const stringDate of stringDates) {
        const dateData = await getCalendarDateData(user, stringDate);
        if (dateData) {
            dates.push(dateData);
        }
    }
    return dates;
}

export const flattenYearData = (yearData) => {
    let dateData = []
    if (!yearData) {
        return []
    }
    const data = Object.values(yearData).map(month => Object.values(month));
    data.forEach(d => {
        if (d?.length) {
            dateData = [...dateData, ...d];
        }
    })
    return dateData;
}

export const getAllCalendarDatesDataPreviousCurrentNextYear = async (user: string, today: Date): Promise<ICalendarData[]> => {
    const previousYear = convertTimestampToStringObject(subYears(today, 1).getTime()).year
    const currentYear = convertTimestampToStringObject(today.getTime()).year;
    const nextYear = convertTimestampToStringObject(addYears(today, 1).getTime()).year

    const pData = await getDatesDataForYear(user, previousYear)
    const cData = await getDatesDataForYear(user, currentYear)
    const nData = await getDatesDataForYear(user, nextYear)

    const pNormalizedData = flattenYearData(pData);
    const cNormalizedData = flattenYearData(cData);
    const nNormalizedData = flattenYearData(nData);
    const combinedData: ICalendarData[] = [...pNormalizedData, ...cNormalizedData, ...nNormalizedData]
    if (!combinedData) {
        return []
    }
    return combinedData;
}