import * as React from 'react';
import './Row.scss'

const Row = ({ index = 0, style = null, className = "", onClick = undefined, dataTestid = undefined, children }) => {
    return <div key={index} style={style} onClick={onClick} className={`row${className ? " " + className : ""}`} data-testid={dataTestid}>
        {children}
    </div>;
};

export { Row };
