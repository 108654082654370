import * as React from 'react';
import { DeleteForever } from '@mui/icons-material';
import Modal from '../components/modal/Modal';
import { Button } from '../components/button/Button';
// import 'DeleteIcon.scss';

const DeleteIcon = ({ onClick = null, className = "", confirmDelete = true }) => {
    const [showModal, setShowModal] = React.useState(false);

    const renderModal = () => <div className="delete-modal">
        <Modal showModal={showModal} setShowModal={setShowModal}>
            <div style={{ backgroundColor: 'white', padding: '8px' }}/* className="delete-dialog" */>Delete?</div>
            <div>
                <Button className="delete-button" label={'OK'} onClick={() => {
                    if (onClick) {
                        onClick()
                        setShowModal(false);
                    }
                }} />
                <Button label={'Cancel'} onClick={() => setShowModal(false)} />
            </div>
        </Modal></div>

    return <><DeleteForever className={`delete-icon${className ? " " + className : ""}`} onClick={() => {
        if (confirmDelete) {
            setShowModal(!showModal)
        }
        else {
            onClick();
        }
    }}></DeleteForever>
        {showModal && confirmDelete && renderModal()}
    </>


};

export { DeleteIcon };
