import * as React from 'react';
import {WbSunny} from '@mui/icons-material';

const SunIcon = ({ onClick = null, className = "" }) => {
    return <WbSunny className={`sun-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { SunIcon };
