import * as React from 'react';
import './Column.scss'

const Column = ({ style = null, className = "", width = undefined, onClick = null, alignLeft = false, dataTestid = undefined, children }) => {
    return <div data-testid={dataTestid} onClick={onClick} style={{ ...style, width, alignItems: alignLeft ? "flex-start" : "", display: 'flex' }} className={`column${className ? " " + className : ""}`}>
        {children}
    </div>;
};

export { Column };
