import * as React from 'react'

const Label = ({ text, className = "", onClick = null }) => {
    return (
        <label onClick={() => {
            if (onClick) {
                onClick(text);
            }
        }} className={"label" + (className ? " " + className : "")}>{text}</label>
    )
}

export { Label }
