import * as React from 'react';
import {Stop} from '@mui/icons-material';

const SIcon = ({ onClick = null, className = "" }) => {
    return <Stop className={`stop-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { SIcon as StopIcon };
