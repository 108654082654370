import * as React from 'react';
import { Modal as MaterialModal } from '@mui/material';
import "./Modal.scss";

interface IProps {
    showModal: boolean;
    setShowModal: Function;
    className?: string;
    disableEnforceFocus?: boolean;
    onEscape?: Function;
    style?: {}
    children?: any;
    onBackdropClick?: Function
    dataTestid?: string
}
const Modal = ({ showModal, setShowModal, className, disableEnforceFocus, onEscape, style, dataTestid, onBackdropClick, children }: IProps) => {

    React.useEffect(() => {
        if (!showModal) {
            closeModal();
        }
    }, [showModal])

    const closeModal = () => {
        setShowModal(false);
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            onBackdropClick && onBackdropClick();
            setShowModal(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            setShowModal(false);
            onEscape && onEscape();
        }
    }

    return (<div
        className={`mod    al ${className ? " " + className : ""}`}
        onKeyDown={handleKeyDown}
        data-testid={dataTestid}
    >
        <MaterialModal
            disableEnforceFocus={disableEnforceFocus}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={showModal}
            onClose={handleClose}
            onKeyDown={handleKeyDown}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div data-testid={dataTestid  ? `${dataTestid}_modal-content` : "modal-content"} className="modal-content" style={style}>{children}</div>
        </MaterialModal>
    </div>)
}

export default Modal