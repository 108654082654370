import * as React from 'react';
import {Work} from '@mui/icons-material/';

const WorkIcon = ({ onClick = null, className = "" }) => {
    return <Work className={`work-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { WorkIcon };
