import { v4 as uuid } from 'uuid';
import deepEqual from "deep-equal";

export const getRandomId = (): string => {
    return "" + Math.floor(Math.random() * 1000000)
}

export const getRandomColor = (): string => "#" + Math.floor(Math.random() * 16777215).toString(16);

export const getRandomStringId = (length: number): string => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const getRandomItem = <T>(arr: T[]): T => {
    return arr[Math.floor(Math.random() * arr.length)]
}

export const generateId = () => {
    const id: string = uuid();
    const prefix = String.fromCharCode(97 + Math.floor(Math.random() * 26)); // Generates a random lowercase letter
    return prefix + id.slice(1); // Replace the first character of the UUID with the prefix
}

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
}

export const replaceSwedishCharacters = (text: string) => {
    let newText = replaceAll(text, "å", "a");
    newText = replaceAll(newText, "Å", "A");
    newText = replaceAll(newText, "ä", "a");
    newText = replaceAll(newText, "Ä", "A");
    newText = replaceAll(newText, "ö", "o");
    newText = replaceAll(newText, "Ö", "O");
    return newText;
}

export const objectToArray = obj => Object.keys(obj).map((k) => obj[k])

export const insertAtIndex = (arr, index, item) => {
    return arr.splice(index, 0, item);
}

export const moveToIndex = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
};

export const findAndUpdateObject = (arr, searchFunction, newObject) => {
    var foundIndex = arr.findIndex(searchFunction);
    arr[foundIndex] = { ...newObject };
}

export const removeAtIndex = <T>(arr: Array<T>, index: number) => {
    const item = arr.splice(index, 1);
    return item;
}

export const leadingZero = (time: string | number) => {
    const strTime: string = time.toString();
    if (strTime.length < 2) {
        time = "0" + time;
    }
    return time;
}

export const leadingZeroString = (time: string) => {
    if (time.length < 2) {
        time = "0" + time;
    }
    return time;
}

export const removeLeadingZeros = (number: string) => {
    let numberWithoutLeadingZero = number;
    while (numberWithoutLeadingZero.charAt(0) === '0') {
        numberWithoutLeadingZero = numberWithoutLeadingZero.substring(1);
    }
    return numberWithoutLeadingZero;
}

export const clearObjectOfUndefines = (obj) => {
    return Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
}

export const clearArrayOfUndefines = arr => {
    return arr.filter(a => a);
}
export const getItemWithMostOccurences = array => {
    const arr = [...array]
    return arr.sort((a, b) =>
        arr.filter(v => v === a).length
        - arr.filter(v => v === b).length
    ).pop();
}

export const sortAttributeFunc = (a, b, nextAttributes: string[]) => {
    const attribute: string = removeAtIndex(nextAttributes, 0)[0];
    if (a[attribute] && !b[attribute]) {
        return -1;
    }
    else if (!a[attribute] && b[attribute]) {
        return 1;
    }
    else if (a[attribute] && b[attribute]) {
        return a[attribute] > b[attribute] ? -1 : 1
    }
    else if (nextAttributes?.length) {
        return sortAttributeFunc(a, b, nextAttributes)
    }
    else {
        return 1;
    }
}

export const mergeArraysWithoutDuplicates = (a, b) => {
    return Array.from(new Set([...a, ...b]));
}

export const deleteObjectFromArray = (obj: { id: string }, array): [] => {
    return array.filter(item => item.id !== obj.id);
}

export const addOrDeleteFromArray = (arr: string[], newItem: string): string[] => {
    if (arr.includes(newItem)) {
        return arr.filter(a => a !== newItem);
    }
    else {
        arr.push(newItem);
        return [...arr];
    }
}

export const cl = (message: string) => console.log(message);

export const objectHasProperties = obj => !obj ? false : Object.keys(obj)?.length > 0

export const twoDecimals = val => Math.round(val * 100) / 100
export const oneDecimal = val => Math.round(val * 10) / 10

export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const subStringAfterLastSlash = (text: string) => {
    const n = text.lastIndexOf('/');
    return text.substring(n + 1);
}

export const subStringBeforeLastSlash = (text: string) => {
    return text.substring(0, text.lastIndexOf('/'));
}

export const objectsAreEqual = (object1: {}, object2: {}): boolean => {
    if ((!object1 && object2) || (object1 && !object2)) {
        return false
    }
    return deepEqual(object1, object2)
}

export const getSubstringBetweenTwoTexts = (text: string, startText: string, endText: string): string => {
    return text.substring(
        text.indexOf(startText) + 1,
        text.lastIndexOf(endText)
    );
}

export const groupBy = (arr, key) => arr.reduce((acc, item) => ((acc[item[key]] = [...(acc[item[key]] || []), item]), acc), {});

export const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

export const removeAllWhitespace = (str) => {
    return str.replaceAll(/\s/g, '')
}

export const asyncMap = async (list, iterationFunc) => {
    const items = [];
    let index = 0;
    for (const item of list) {
        const r = await iterationFunc(item, index);
        items.push(r);
        index++;
    }
    return items;
}

export const asyncFilter = async <T>(list: T[], filterFunc: (item: T, index) => Promise<T>): Promise<T[]> => {
    const items = [];
    let index = 0;
    for (const item of list) {
        const r = await filterFunc(item, index);
        if (r) {
            items.push(item);
        }
        index++;
    }
    return items;
}

export const asyncForEach = async (list, iterationFunc) => {
    let index = 0;
    for (const item of list) {
        await iterationFunc(item, index);
        index++;
    }
};

export const shuffleArray = (array: any[]) => {
    for (var i = array.length - 1; i > 0; i--) {

        // Generate random number 
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
};

// export const arraysHaveAtLeastOneMatch = async (arr1: [], arr2: [], matchFunc: (item1: any, item2: any) => Promise<boolean>): Promise<boolean> => {
//     for(const item of arr1){
//         for(const item2 of arr2){
//             const isMatch = await matchFunc(item, item2)
//             if(isMatch){
//                 return true
//             }
//         }
//     }
//     return false
// }

export const arraysHaveAtLeastOneMatch = async <T, U>(arr1: T[], arr2: U[], callback: (item1: T, item2: U) => Promise<boolean>): Promise<boolean> => {
    for (const item1 of arr1) {
        for (const item2 of arr2) {
            const result = await callback(item1, item2);
            if (result) {
                return true;
            }
        }
    }
    return false;
};

export const startPerformanceTimer = () => performance.now();

export const endPerformanceTimer = (start = 0) => {
    if (start > 0) {
        printPerformanceTimer(start, performance.now())
        return 
    }
    else {
        return performance.now()
    }
}
export const printPerformanceTimer = (startTime: number, endTime: number) => console.log(`************************ ${endTime - startTime} milliseconds ************************`)