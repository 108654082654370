import * as React from 'react'
import "./TopBar.scss";
import { Button } from '../components/button/Button';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';
import { BaseModule } from '../module/modules/BaseModule';
import { Checkbox } from '../components/checkbox/Checkbox';
import { Column } from '../components/layout/column/Column';
import { MarginBottom } from '../components/layout/margin/MarginBottom';
import { deleteData, getFirebaseSession } from '../utils/Firebase';
import { breakpointTypes, cols, GET_USER } from '../utils/constants';
import { TData } from './interface';

const TopBarMenu = ({ close }) => {
    const { setUser, lockedModules, setLockedModules } = React.useContext(AppContext);
    let navigate = useNavigate();

    const autoPack = (isMobile: boolean, currentPageModules: BaseModule[], currentBreakpoint: breakpointTypes, dataContext: TData, forceUpdate: Function) => {
        // const gaps = [];
        const colsWidth = cols.lg;
        if (!isMobile) {
            // console.log(layouts[currentBreakpoint]);
            let currentRow = 1;
            let currentX = 0;
            let totalWidthCurrentRow = 0;
            let totalCurrentHeight = 0;
            let highestOnCurrentRow = 0;

            // const newModules = currentPageModules.sort((a, b) => {
            //     if (a.sitemapData.grid.lg.y < b.sitemapData.grid.lg.y) {
            //         return -1;
            //     }
            //     else if (a.sitemapData.grid.lg.y > b.sitemapData.grid.lg.y) {
            //         return 1;
            //     }
            //     else if (a.sitemapData.grid.lg.y === b.sitemapData.grid.lg.y) {
            //         if (a.sitemapData.grid.lg.x < b.sitemapData.grid.lg.x) {
            //             return -1;
            //         }
            //         else if (a.sitemapData.grid.lg.x > b.sitemapData.grid.lg.x) {
            //             return 1;
            //         }
            //         else {
            //             return -1;
            //         }
            //     }
            //     else {
            //         return -1;
            //     }
            // }).reduce((previous, modulee, index, array) => {

            //     const moduleType = modulee.type;
            //     if (moduleType === modules.BOARDS || moduleType === modules.PROGRESS_BAR) {
            //         modulee.minimize(currentBreakpoint);
            //         dataContext.sitemapData.setSitemapData(modulee.true, false);
            //     }

            //     let w = modulee.autoPackingDimensions.w
            //     let h = modulee.autoPackingDimensions.h
            //     let x;
            //     let y;


            //     highestOnCurrentRow = h > highestOnCurrentRow ? h : highestOnCurrentRow;
            //     const previousModule = index === 0 ? undefined : array[index - 1];

            //     const newRow = totalWidthCurrentRow >= colsWidth;
            //     if (newRow) {
            //         currentRow++;
            //         currentX = 0;
            //         totalWidthCurrentRow = 0;
            //         totalCurrentHeight += highestOnCurrentRow;
            //         highestOnCurrentRow = 0;
            //         x = 0;
            //     }
            //     else {
            //         x = !previousModule ? 0 : totalWidthCurrentRow;
            //     }
            //     totalWidthCurrentRow += w;

            //     y = totalCurrentHeight

            //     previous.push(modulee);

            //     modulee.sitemapData.grid.lg.x = x;
            //     modulee.sitemapData.grid.lg.y = y;
            //     modulee.sitemapData.grid.lg.w = w;
            //     modulee.sitemapData.grid.lg.h = h;

            //     currentX++;

            //     return previous;

            // }, []);
            // dataContext.modules.updateModules(newModules, true);
            forceUpdate();
        }
    }

    const deleteAccount = async () => {
        //@ts-ignore
        const firebaseSession = await getFirebaseSession()
        const authUser = firebaseSession.auth().currentUser;
        authUser.delete().then(async () => {
            await deleteData(`${GET_USER(authUser.uid)}`);
            navigate(`/`);
            close()
        }).catch((error) => {
            alert(error);
            console.log(error);
        });
    }

    const toggleStatic = () => {
        setLockedModules(!lockedModules);
        // dataContext.modules.refresh();
        // forceUpdate();
    }

    const logout = async () => {
        //@ts-ignore
        const firebaseSession = await getFirebaseSession()
        firebaseSession.auth().signOut();
        setUser("");
        navigate(`/`);
    }

    return (
        <Column alignLeft>
            <Checkbox label={"Lås modulerna"} checked={lockedModules} onChange={checked => toggleStatic()} />
            {/* <Button label="Autopacka" onClick={() => autoPack(isMobile, Object.values(dataContext.modules.currentPageModules), currentBreakpoint, dataContext, () => {
                dataContext.modules.setCurrentPageModules({ ...dataContext.modules.setCurrentPageModules })
                dataContext.modules.refresh();
            })} /> */}
            <MarginBottom />
            <Button label={"Logga ut"} onClick={logout} />
            <MarginBottom />
            <Button label={'Radera kontot'} onClick={() => {
                deleteAccount();
                // alert("Funktionaliteten är utkommenterad just nu")
            }} />
        </Column>
    )
}

export { TopBarMenu }
