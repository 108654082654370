import * as React from 'react'
import "./TopBar.scss";
import { AppContext } from './AppContext';
import { readData } from '../utils/Firebase';
import { GET_USER } from '../utils/constants';
import { MenuIcon } from '../icons/MenuIcon';
import { useNavigate } from 'react-router-dom';
import { Row } from '../components/layout/row/Row';
import Modal from '../components/modal/Modal';
import { TopBarMenu } from './TopBarMenu';

const TopBar = () => {

    const { user } = React.useContext(AppContext);
    const [userName, setUsername] = React.useState("");
    const [showSettings, setShowSettings] = React.useState(false);
    // const [showSubBar, setShowSubBar] = React.useState(true);
    let navigate = useNavigate();

    React.useEffect(() => {
        if (user) {
            readData(`${GET_USER(user)}/profile/name`).then(setUsername)
        }
    }, [user])

    return (
        <>
            <div className="topbar" data-testid="topbar">
                <h1><span style={{ fontSize: "15px" }}>orga</span><span>NICER</span></h1>
                <h1>Välkommen {userName}</h1>
                <Row>
                    <MenuIcon onClick={() => {
                        // setShowSubBar(!showSubBar)
                        setShowSettings(true);
                    }} />
                </Row>
            </div>
            {/* {!showSubBar && <MarginBottom />} */}
            {/* {showSubBar && } */}
            <Modal showModal={showSettings} setShowModal={setShowSettings}>
                <TopBarMenu close={() => {
                    setShowSettings(false);
                }} />
            </Modal>
        </>
    )
}

export { TopBar }
