import * as React from 'react'
import { ChecklistIcon } from '../../icons/ChecklistIcon'
import {
    ActivityIcon, ArrowBackIcon, ArrowDownIcon, ArrowForwardIcon, ArrowUpIcon, CardIcon, CheckIcon, ClockIcon, CloseIcon, DeleteIcon, EditIcon,
    ExclamationIcon, FoodIcon, FootballIcon, KidsIcon, MailIcon, MenuIcon, MinimizeIcon, MinusIcon, PausIcon, PlayIcon,
    PlusIcon,
    QuestionMarkIcon, SettingsIcon, SickIcon, StopIcon, SunIcon, TableIcon, TagIcon, ThumbsDownIcon, ThumbsUpIcon, VabIcon, WorkIcon
} from '../../icons/Icons'
import { LockIcon } from '../../icons/LockIcon'
import { LockOpenIcon } from '../../icons/LockOpenIcon'
import { ReloadIcon } from '../../icons/ReloadIcon'

export enum IconTypes {
    ACTIVITY = "ActivityIcon",
    ARROWBACK = "ArrowBackIcon",
    ARROWDOWN = "ArrowDownIcon",
    ARROWFORWARD = "ArrowForwardIcon",
    ARROWUP = "ArrowUpIcon",
    CARD = "CardIcon",
    CHECK = "CheckIcon",
    CHECKLIST = "ChecklistIcon",
    CLOCK = "ClockIcon",
    CLOSE = "CloseIcon",
    DELETE = "DeleteIcon",
    EDIT = "EditIcon",
    EXCLAMATION = "Exclamation",
    FOOD = "Food",
    FOOTBALL = "FootballIcon",
    MAIL = "MailIcon",
    MENU = "MenuIcon",
    MINIMIZE = "MinimizeIcon",
    MINUS = "MinusIcon",
    KIDS = "KidsIcon",
    LOCK = "LockIcon",
    LOCKOPEN = "LockOpenIcon",
    PAUS = "PausIcon",
    PLAY = "PlayIcon",
    PLUS = "PlusIcon",
    QUESTIONMARK = "QuestionMark",
    RELOAD = "Reload",
    SETTINGS = "SettingsIcon",
    SICK = "SickIcon",
    STOP = "StopIcon",
    SUN = "SunIcon",
    TABLE = "TableIcon",
    TAG = "TagIcon",
    THUMBDOWN = "ThumbDownIcon",
    THUMBUP = "ThumbUpIcon",
    VAB = "VabIcon",
    WORK = "WorkIcon"
}

export const StringIcons = (iconName: IconTypes, onClick, className = "") => {
    return (
        <>
            {iconName === IconTypes.ARROWBACK && <ArrowBackIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.ARROWDOWN && <ArrowDownIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.ARROWFORWARD && <ArrowForwardIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.ARROWUP && <ArrowUpIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.ACTIVITY && <ActivityIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.CARD && <CardIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.CHECK && <CheckIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.CHECKLIST && <ChecklistIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.CLOCK && <ClockIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.DELETE && <DeleteIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.EDIT && <EditIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.EXCLAMATION && <ExclamationIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.FOOD && <FoodIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.FOOTBALL && <FootballIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.LOCK && <LockIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.LOCKOPEN && <LockOpenIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.MAIL && <MailIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.MENU && <MenuIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.MINIMIZE && <MinimizeIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.MINUS && <MinusIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.KIDS && <KidsIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.PAUS && <PausIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.PLAY && <PlayIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.PLUS && <PlusIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.QUESTIONMARK && <QuestionMarkIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.RELOAD && <ReloadIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.SETTINGS && <SettingsIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.STOP && <StopIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.SUN && <SunIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.SICK && <SickIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.TABLE && <TableIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.TAG && <TagIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.THUMBUP && <ThumbsUpIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.THUMBDOWN && <ThumbsDownIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.VAB && <VabIcon onClick={onClick} className={className} />}
            {iconName === IconTypes.WORK && <WorkIcon onClick={onClick} className={className} />}
        </>
    )
}

export default StringIcons