import "./styles.scss"
import "react-grid-layout/css/styles.css"
// import "react-resizable/css/styles.css"
import "react-resizable/css/styles.css"

import { TData } from "../../../app/interface";
import { BaseModule, IBaseModule } from "../BaseModule";
import { breakpointTypes, getModulesPath, modules } from "../../../utils/constants";
import { Layout } from "../../Layout";
// import { CardModule } from "../CardModule/CardModule";
import { writeData } from "../../../utils/Firebase";
import { GridItem } from "./GridItem";
import { ReactGrid } from "./ReactGrid";
import { IModuleRelationsProps, ModuleRelations } from "../ActivityTableModule/ModuleRelations";

export interface IReactGridModuleProps {
    testDraggable?: boolean;
    onDragStart: (newGrid: GridItem) => void;
    onDragStop: (gridModule: ReactGridModule) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (gridModule: ReactGridModule) => void;
    filteredIds?: string[];
    currentBreakpoint: breakpointTypes;
}

export class ReactGridModule extends ModuleRelations {
    testDraggable?: boolean;
    onDragStart: (newGrid: GridItem) => void;
    onDragStop: (gridModule: ReactGridModule) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (gridModule: ReactGridModule) => void;
    onRemoveModule: (moduleId: BaseModule, gridModule: ReactGridModule) => void;
    filteredIds?: string[];
    currentBreakpoint: breakpointTypes;

    constructor(baseProps: IBaseModule, moduleRelationProps: IModuleRelationsProps, reactGridModuleProps: IReactGridModuleProps) {
        super(baseProps, moduleRelationProps);
        Object.assign(this, reactGridModuleProps);
        this.layout = new Layout(moduleRelationProps.layout);
        this.childModules = moduleRelationProps.childModules || [];
        this.testDraggable = reactGridModuleProps.testDraggable;
        this.childModules.forEach(m => {
            if (m instanceof ModuleRelations || m instanceof ModuleRelations) {
                m.parentModule = this
            }
        });
        this.noBox = true;
    }

    getDataContextSubscribeArgs(dataContext: TData): any[] {
        return [dataContext?.modules?.allModules]
    }

    getCreationDialog(): JSX.Element {
        return null;
    }
    customDelete(dataContext: TData): void {

    }
    renderTopMenu(dataContext: TData): JSX.Element {
        return null;
    }

    render(): JSX.Element {
        return <>
            <ReactGrid
                id={this.id}
                filteredIds={this.filteredIds}
                layout={this.layout}
                modules={this.childModules}
                onDragStart={this.onDragStart}
                onDragStop={(newGrid: GridItem[], modules: BaseModule[]) => {
                    this.layout.updateGridItems(newGrid, this.currentBreakpoint);
                    this.onDragStop(this)
                }}
                onDragOver={this.onDragOver}
                onResizeStop={(gridItems: GridItem[]) => {
                    this.layout.updateGridItems(gridItems, this.currentBreakpoint);
                    this.onResizeStop(this)
                }}
                currentBreakpoint={this.currentBreakpoint}
                testDraggable={this.testDraggable}
            />
        </>
    }

    toFirebaseObject() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            layout: { lg: this.layout?.lg, sm: this.layout?.lg }
        }
    }

    updateLayoutFirebase() {
        writeData(`${getModulesPath(this.user, this.id)}/layout`, { lg: this.layout?.lg, sm: this.layout?.lg });
    }

}