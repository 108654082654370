import * as React from 'react'
import { AppContext } from '../../app/AppContext';
import { useSettings } from '../../hooks/useSettings';
import { Checkbox } from '../checkbox/Checkbox';
import Modal from '../modal/Modal';
import "./Table.scss";

export interface ICell {
    columnId: string,
    content: any;
    extra?: any;
}

export interface IRow {
    id: string;
    cells: ICell[];
    rowStyling?: {}
}

export interface IColumn {
    id: string;
    label: string;
    onClickCell?: (cell: ICell) => void;
    onClickCellModal?: (row, cell, extra) => Promise<JSX.Element>
    extra?: any;
    formatter?: (row: IRow, cell: ICell, extra?: any) => any;
}

export interface ISort {
    colId: string;
    asc: boolean;
}

interface ITable {
    settingsId: string;
    columns: IColumn[];
    rows: IRow[];
    customSort?: ISort,
    className?: string;
    mobileCells?: string[];
    testId?: string
    // onSort?: (sortOrder: ISort) => void;
}

const Table = ({ settingsId, columns, rows, customSort,/* onSort, */ className, mobileCells, testId }: ITable) => {
    const { user, isMobile } = React.useContext(AppContext);
    const [showModal, setShowModal] = React.useState("");
    const [modalContent, setModalContent] = React.useState<JSX.Element>();
    const { getSetting, setSetting, updateFirebase } = useSettings(user);
    const setting = getSetting(settingsId);
    const [sortOrder, setSortOrder] = React.useState<ISort>(customSort || { colId: "id", asc: true });
    const [rws, setRws] = React.useState([]);

    React.useEffect(() => {
        init();
    }, [rows])

    const getColumnSetting = (columnId: string) => {
        if (settingsId && setting && columnId in setting) {
            return setting[columnId];
        }
        return true;
    }

    const getMobileColumns = (columns: IColumn[]) => isMobile && mobileCells?.length ? columns.filter(column => mobileCells.some(mc => mc === column.id)) : columns

    const columnsMenu = () => {
        const cols = getMobileColumns(columns)
        return cols.map((column: IColumn, index: number) => {
            const checked = getColumnSetting(column.id)
            return <React.Fragment key={index}><Checkbox className="margin-right" label={column.label} checked={false}/*  onChange={async checked => {
            }} */ /></React.Fragment>
        })
    }

    const renderHeaders = () => {
        const cols = getMobileColumns(columns)
        return <tr>
            {cols.map((column: IColumn, index: number) => {
                if (!getColumnSetting(column.id)) {
                    return false;
                }
                return <React.Fragment key={index}>
                    <th onClick={() => {
                        const newSortObject = { colId: column.id, asc: sortOrder.colId === column.id ? !sortOrder.asc : sortOrder.asc };
                        setSortOrder(newSortObject)
                    }}>
                        {column.label}
                    </th>
                </React.Fragment>
            })}
        </tr>
    }

    const sortRows = (rows: IRow[]) => {
        if (rows) {
            rows?.sort((a: IRow, b: IRow) => {
                const aValue = a.cells.find((c: ICell) => c.columnId === sortOrder.colId)?.content || ""
                const bValue = b.cells.find((c: ICell) => c.columnId === sortOrder.colId)?.content || ""
                if (aValue && !bValue) {
                    return -1
                }
                else if (!aValue && bValue) {
                    return 1;
                }
                else if (!aValue && !bValue) {
                    return 0;
                }
                else {
                    if (!sortOrder.asc) {
                        return aValue > bValue ? 1 : -1;
                    }
                    else {
                        return aValue > bValue ? -1 : 1;
                    }
                }
            })
        }
    }

    const getMobileCells = (row) => isMobile && mobileCells?.length ? row.cells.filter(c => mobileCells.some(mc => mc === c.columnId)) : row?.cells;

    const renderRows = () => {
        sortRows(rows);

        const elements = rows?.map((row: IRow, ri) => {
            const cells = getMobileCells(row);
            return <React.Fragment key={row.id}>
                {/* <tr data-testid={`table-row table-row-${ri}`} style={row.rowStyling}> */}
                <tr data-testid={`table-row-${ri}`} style={row.rowStyling}>
                    {cells.map((cell: ICell, ci) => {
                        if (!getColumnSetting(cell.columnId)) {
                            return false;
                        }
                        const column = columns.find((column: IColumn) => cell.columnId === column.id);
                        const formatter = column?.formatter;
                        return <React.Fragment key={cell.columnId + ri.toString() + ci.toString()}>
                            <td
                                onClick={async () => {
                                    if (column?.onClickCell) {
                                        column.onClickCell(cell);
                                    }
                                    if (column?.onClickCellModal) {
                                        setShowModal("test");
                                        const test = await column.onClickCellModal(row, cell, cell.extra)
                                        setModalContent(test);
                                    }
                                }}>
                                {formatter ? formatter(row, cell, cell.extra) : cell.content}
                            </td>
                        </React.Fragment>
                    })}
                </tr>
            </React.Fragment>
        })
        return elements;
    }

    const init = () => {
        const r = renderRows();
        setRws(r);
    }

    return (
        <div data-testid={`${testId}_root`}className={`table${className ? " " + className : ""}`}>
            {rws?.length > 0 &&
            <table data-testid={testId}>
                <thead>
                    {renderHeaders()}
                </thead>
                <tbody>
                    {rws}
                </tbody>
            </table>}
            <Modal showModal={!!showModal} setShowModal={setShowModal}>
                {showModal === "test" && <div>{modalContent}</div>}
            </Modal>
        </div>
    )
}

export { Table }