
export interface IGrid {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    minW?: number;
    minH?: number;
    style?: {};
    moved?: boolean;
    static?: boolean;
    isDraggable?: boolean;
    minimised?: Partial<GridItem>;
    invisible?: boolean;
    parentLayoutId?: string;
}

export class GridItem implements IGrid {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    minW?: number;
    minH?: number;
    style?: {};
    moved?: boolean;
    static?: boolean;
    isDraggable?: boolean;
    minimised?: Partial<GridItem>;
    invisible?: boolean;
    parentLayoutId?: string;

    constructor(gridItem: IGrid, parentLayoutId: string) {
        // Object.assign(this, gridItem);
        Object.assign(
            this,
            Object.keys(gridItem).reduce((obj, key) => {
              if (gridItem[key] !== undefined) {
                obj[key] = gridItem[key];
              }
              return obj;
            }, {})
          );
        this.parentLayoutId = parentLayoutId;
    }

}