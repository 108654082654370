import { firebaseModulePaths, LogTypeEnums } from "../../utils/constants";
import { writeData } from "../../utils/Firebase";
import { getNow } from "../../utils/TimeUtils";
import { clearObjectOfUndefines, generateId } from "../../utils/Utils";
import { ScheduledActivity } from "../activity_generator/ScheduledActivity";
import { TData } from "../../app/interface";
import { ICalendarDate } from "./Calendar";

export interface IEventLog {
    id: string;
    created: number;
    title: string;
    type: LogTypeEnums;
    content: string;
    extra?: any;
}

export interface ISchedule {
    [key: string]: ScheduledActivity;
}

export interface ICalendarData {
    id: string;
    date: string;
    schedule: string[]
    data?: ICalendarDate;
    applicationEventLog?: IEventLog[]
}

export const getDefaultCalendarData = (date: string, defaultSchedule?: string[]): ICalendarData => {
    return {
        id: generateId(), date, schedule: defaultSchedule || [],
        applicationEventLog: [],
         data: {
            icons: [], logs: []
        }
    }
}

export class CalendarData implements ICalendarData {
    id: string;
    date: string;
    schedule: string[];
    data?: ICalendarDate;
    applicationEventLog?: IEventLog[];
    // expanded: boolean

    constructor(calendarData: ICalendarData) {
        if(!calendarData.id) {
            calendarData.id = generateId();
        }
        Object.assign(this, calendarData);
        if (!this.schedule) {
            this.schedule = [];
        }
    }

    hasSchedule(): boolean {
        if (this.schedule) {
            return Object.values(this.schedule).length > 0
        }
        return false;
    }

    scheduleHasActivity(activityId: string): boolean {
        return this.schedule.includes(activityId);
    }

    addScheduledActivity = (scheduledActivity: ScheduledActivity) => {
        if(!this.schedule.includes(scheduledActivity.id)){
            this.schedule.push(scheduledActivity.id);
        }
    }

    createLog(title: string, content: string, type: LogTypeEnums, extra: any = undefined): IEventLog {
        const newLogEntry: IEventLog = {
            id: generateId(),
            created: getNow(),
            title,
            content,
            type,
            extra
        };
        return newLogEntry;
    }

    updateFirebase(user: string) {
        const p = this.getFirebasePath(user)
        clearObjectOfUndefines(this);
        writeData(p, this.convertToFirebaseObject());
    }

    createDiaryEntry = (user: string, logContent: string, titleLog: string = "") => {
        if (!this.data) {
            this.data = { icons: [], logs: []}
        }
        if (!this.data.logs) {
            this.data.logs = [];
        }
        this.data.logs.push({ id: generateId(), created: getNow(), title: titleLog, content: logContent });
        this.updateFirebase(user);
    }

    getSchedule = async (dataContext: TData): Promise<ScheduledActivity[]> => {
        const schedule = await dataContext?.scheduledActivities?.getSchedule(this.schedule) || [];
        return schedule;
    }

    convertToFirebaseObject() {

        const firebaseActivity: ICalendarData = {
            id: this.id,
            date: this.date,
            schedule: this.schedule,
            data: this.data,
            applicationEventLog: this.applicationEventLog,
        }
        //@ts-ignore
        delete firebaseActivity.createDiaryEntry;
        //@ts-ignore
        delete firebaseActivity.canAutoScheduleToday;
        //@ts-ignore
        delete firebaseActivity.getSchedule;
        return firebaseActivity;
    }

    getFirebasePath(user: string) {
        return firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, this.date);
    }

    deleteScheduledActivity(user: string, dataContext: TData) {
        dataContext.calendarData.deleteScheduledActivity(this.date, this.id);
        this.updateFirebase(user);
    }

    toString(){
        return "keevviiire"
    }
}