import * as React from 'react'
import { BaseModule, IBaseModule } from '../BaseModule';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { breakpointTypes, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import "./Card.scss";
import classNames from 'classnames';
import { BoxedModule } from '../../BoxedModule';
import { generateId } from '../../../utils/Utils';
import { GridItem, IGrid } from '../ReactGridModule/GridItem';
import { ReactGrid } from '../ReactGridModule/ReactGrid';
import { IModuleRelationsProps, ModuleRelations } from '../ActivityTableModule/ModuleRelations';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { ModuleCreator } from '../../module_creator/ModuleCreator';
import { Column } from '../../../components/layout/column/Column';
import { Row } from '../../../components/layout/row/Row';
import { removeModule } from '../../../app/utils';

export interface ICardModuleProps {
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    containerOnDragStop: (self: BaseModule) => void;
    containerOnResizeStop: (self: BaseModule) => void;
    onCheckboxChange?: (module: CardModule) => void;
    checkbox?: boolean;
    checkboxChecked?: boolean;
    moduleOptions?: any[];
}

export class CardModule extends ModuleRelations implements ICardModuleProps {
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    containerOnDragStop: (self: BaseModule) => void;
    containerOnResizeStop: (self: BaseModule) => void;
    onCheckboxChange?: (module: CardModule) => void;
    checkbox?: boolean;
    checkboxChecked?: boolean;
    moduleOptions?: any[];

    constructor(baseProps: IBaseModule, cardModuleProps: ICardModuleProps, relationProps: IModuleRelationsProps) {
        super(baseProps, relationProps);
        this.noBox = true;
        Object.assign(this, cardModuleProps);
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }, this.id)
        }
    }

    renderTopMenu(): JSX.Element {
        return null
    }

    renderSettings = (): JSX.Element => {
        return <Checkbox label='Använd checkbox' checked={this.checkboxChecked} onChange={checked => {
            this.checkboxChecked = checked;
            this.updateFirebase();
        }} />
    }

    createCardDialog = (): JSX.Element => {
        return <ModuleCreator
            horisontal
            closeIconTestid="subbar__close-icon"
            options={[
                {
                    name: modules.CARD,
                    onClick: CardModule.getCreationDialog(this.user, async (newModule: CardModule) => {
                        newModule.parentModule = this
                        this.addChildModule(newModule, this);
                        this.closeModal();
                        this.showCardContainer();
                        newModule.closeModal();
                    }),
                    testId: 'create-card',
                    title: 'Skapa nytt kort'
                },
                // this.moduleOptions?.length > 0 ? this.moduleOptions : []
            ]}
            user={this.user}
            dataTestid={''}
        />
    }

    renderGrid = () => {
        return this.layout && <ReactGrid
            id={this.id}
            layout={this.layout}
            modules={this.childModules}
            onDragStart={function (newGrid: any): void {

            }}
            onDragStop={(newGrid: IGrid[], modules: any[]) => {
                this.layout.updateGridItems(newGrid, breakpointTypes.LG);
                this.layout.updateGridItems(newGrid, breakpointTypes.SM);
                this.containerOnDragStop(this)
            }}
            onDragOver={function (e: React.DragEvent<HTMLDivElement>): void {

            }}
            onResizeStop={(newGrid: IGrid[]) => {
                this.layout.updateGridItems(newGrid, breakpointTypes.LG);
                this.layout.updateGridItems(newGrid, breakpointTypes.SM);
                this.containerOnResizeStop(this);
            }}
            currentBreakpoint={this.getCurrentBreakpoint()}
        />
    }

    cardContainerContent = () => {
        return <Column alignLeft className="scheduled-activity-content">
            <Row>
                {this.createCardDialog()}
                {this.renderSettings()}
            </Row>
            {this.renderGrid()}
        </Column>
    }

    containerContent = () => {
        return this.cardContainerContent();
    }

    showCardContainer = () => {
        this.toggleModal(<BoxedModule
            testId={`card-container`}
            moduleId={''}
            moduleName={this.name}
            onDelete={() => {
                removeModule(this);
            }}
            dragHandle={''}
            onHeaderNameUpdate={(name: string) => {

            }}
            content={<div>
                {this.containerContent()}
            </div>}
        />
        )
    }

    toggleChecked = (checked: boolean) => {
        this.checkboxChecked = checked;
        this.onCheckboxChange && this.onCheckboxChange(this);
    }

    renderCheckbox(): JSX.Element {
        return this.checkbox && <Checkbox
            checked={this.checkboxChecked}
            onChange={this.toggleChecked} />
    }

    renderContent(): JSX.Element {
        return <div onClick={() => {
            this.showCardContainer()
        }}>
            {this.name}
        </div>
    }

    renderCardStandard(): JSX.Element {
        return <>
            {this.renderCheckbox()}
            {this.renderContent()}
        </>
    }

    renderFront(): JSX.Element {
        return <div
            className={classNames(
                `card`,
                this.parentModule?.id,
                this.parentModule?.id === "LayoutStartPoint" && 'card-no-parent'
            )}
        >

            <div
                data-testid="card-click-area"
                className="prevent-drag card-click-area"
            >
                {this.renderCardStandard()}
            </div>
        </div>
    }

    render(): JSX.Element {
        return this.renderFront()
    }

    toFirebaseObject() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            layout: { lg: this.layout?.lg, sm: this.layout?.lg },
            parentModule: this.parentModule?.id,
            checkbox: this.checkbox,
            checkboxChecked: this.checkboxChecked
        }
    }

    static createModule = (baseModuleProps?: Partial<IBaseModule>, cardModuleProps?: Partial<ICardModuleProps>, relationProps?: Partial<IModuleRelationsProps>): CardModule => {
        const moduleRelations: IModuleRelationsProps = {
            parentModule: null,
            childModules: [],
            layout: null,
            ...relationProps
        }

        const newCardModule = new CardModule(
            {
                id: generateId(),
                name: "",
                type: modules.CARD,
                onDelete: () => { },
                user: "user",
                ...baseModuleProps
            },
            {
                containerOnDragStop: (self: BaseModule) => { },
                containerOnResizeStop: (self: BaseModule) => { },
                ...cardModuleProps
            },
            moduleRelations
        )
        if (newCardModule.parentModule) {
            newCardModule.onCreateChildModule = newCardModule.parentModule.onCreateChildModule;
            newCardModule.onRemoveChildModule = newCardModule.parentModule.onRemoveChildModule;
        }
        return newCardModule;
    }

    static getCreationDialog(user: string, onCreate: (newModule: CardModule) => void): JSX.Element {
        return <div>
            <InputConfirm
                label='Namn på kort'
                onConfirm={(name) => {
                    const type = modules.CARD;
                    const newModule = CardModule.createModule({ user, id: generateId(), name, type })
                    onCreate(newModule);
                }}
            />
        </div>
    }

}