import { useEffect, useState } from "react";

export const useContainerDimensions = (myRef) => {

    const getDimensions = () => ({
        width: myRef?.current?.offsetWidth || 0,
        height: myRef?.current?.offsetHeight || 0
    })

    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef?.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])
    return dimensions;
};