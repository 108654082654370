import * as React from 'react'
import { useState } from 'react'
import { modules } from '../../utils/constants'
import "./ModuleCreator.scss";
import { CardIcon } from '../../icons/CardIcon';
import Modal from '../../components/modal/Modal';
import { BaseModule } from '../modules/BaseModule';
import { Column } from '../../components/layout/column/Column';
import { CloseIcon } from '../../icons/CloseIcon';

interface IModuleCreator {
    user: string;
    options: {
        name: string,
        title: string,
        onClick: any,
        testId: string
    }[]
    // onCreate: (module: BaseModule) => void;
    dataTestid?: string;
    horisontal?: boolean
    closeIconTestid?: string;
}

const ModuleCreator = (props: IModuleCreator) => {
    const { horisontal, options, closeIconTestid } = props
    const [modalContent, setModalContent] = React.useState();

    const columnStyles = { alignItems: 'flex-start' }

    return <div className="module-creator" style={{ display: 'flex', flexDirection: horisontal ? 'row' : 'column' }}>
        {options && options.map(option => {
            return (!options || options.map(o => o.name).includes(modules.CARD)) && <React.Fragment key={option.name}>
                <Column
                    dataTestid={option.testId}
                    style={columnStyles}
                    className="margin-right"
                    onClick={() => setModalContent(option.onClick)}
                >
                    <CardIcon />
                    {option.title}
                </Column>
            </React.Fragment>
        })}

        {modalContent && <Modal showModal={!!modalContent} setShowModal={setModalContent}>
            <CloseIcon onClick={() => { setModalContent(undefined) }} dataTestid={closeIconTestid} />
            {modalContent}
        </Modal>}

    </div>
}

export { ModuleCreator }
