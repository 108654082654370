import * as React from 'react';
import { AddCard } from '@mui/icons-material/';

interface ICardIcon {
    onClick?: Function;
    color?: string;
    className?: any;
}

const CardIcon = ({ onClick = null, color = undefined, className = "" }: ICardIcon) => {
    return <AddCard style={color ? { color } : undefined} className={`card-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { CardIcon };
