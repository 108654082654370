import * as React from 'react'
import { useEffect, useState } from 'react'
import { convertMillisToDurationObject, Millis } from '../../../utils/TimeUtils';
import { Label } from '../../label/Label';
import { NumberStepper } from '../../NumberStepper/NumberStepper';
import "./DurationPicker.scss";

interface IDurationPicker {
    defaultValue?: number;
    onChange: (millis: number) => void;
}

export const DurationPicker = ({ defaultValue, onChange }: IDurationPicker) => {
    const duration = defaultValue ? convertMillisToDurationObject(defaultValue) : undefined;
    const [weeks, setWeeks] = useState(duration?.weeks || 0);
    const [days, setDays] = useState(duration?.days || 0);
    const [hours, setHours] = useState(duration?.hours || 0);
    const [minutes, setMinutes] = useState(duration?.minutes || 0);
    const [seconds, setSeconds] = useState(duration?.seconds || 0);

    useEffect(() => {
        const millis = (weeks * Millis.week) + (days * Millis.day) + (hours * Millis.hour) + (minutes * Millis.minute) + (seconds * Millis.second);
        onChange(millis);
    }, [weeks, days, hours, minutes, seconds])

    return (
        <div className="duration-picker">
            <div className="duration-picker__labels">
                <Label text="Sekunder:" />
                <Label text="Minuter:" />
                <Label text="Timmar:" />
                <Label text="Dagar:" />
                <Label text="Veckor:" />
            </div>
            <div className="duration-picker__inputs">
                <NumberStepper value={seconds} step={1} onChange={setSeconds} />
                <NumberStepper value={minutes} step={1} onChange={setMinutes} />
                <NumberStepper value={hours} step={1} onChange={setHours} />
                <NumberStepper value={days} step={1} onChange={setDays} />
                <NumberStepper value={weeks} step={1} onChange={setWeeks} />
            </div>
        </div>
    )
}
