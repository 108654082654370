import * as React from "react";
import { useState } from "react";
import { ScheduledActivity } from '../../components/activity_generator/ScheduledActivity';
import { readData } from "../../utils/Firebase";
import { ActivityStatus, data, getDataPath } from "../../utils/constants";
import { generateId } from "../../utils/Utils";
import { convertTimestampToStringDate } from "../../utils/TimeUtils";
import { TData, TScheduledActivities } from "../../app/interface";

export const useScheduledActivities = (user): TScheduledActivities => {
    const [scheduledActivities, setScheduledActivities] = useState<{ [key: string]: ScheduledActivity }>({});

    const refreshScheduledActivities = () => {
        setScheduledActivities({ ...scheduledActivities });
    }

    const updateScheduledActivity = async (scheduledActivity: ScheduledActivity, dataContext: TData) => {
        const oldDate = scheduledActivity.startTime ? convertTimestampToStringDate(scheduledActivity.startTime) : null;
        scheduledActivity.updateFirebase(user);
        if (scheduledActivity.startTime > 0) {
            const newDate = convertTimestampToStringDate(scheduledActivity.startTime);
            dataContext.calendarData.updateScheduledActivity(scheduledActivity, oldDate, newDate);
        }
        scheduledActivities[scheduledActivity.id] = scheduledActivity;
        refreshScheduledActivities()
    }

    const deleteScheduledActivity = async (scheduledActivity: ScheduledActivity) => {
        delete scheduledActivities[scheduledActivity.id];
        refreshScheduledActivities();
    }

    const getScheduledActivity = async (scheduledActivityId: string): Promise<ScheduledActivity> => {
        if (scheduledActivityId in scheduledActivities === false) {
            const scheduledActivity = await readData(getDataPath(user, data.SCHEDULED_ACTIVITY, scheduledActivityId));
            if (scheduledActivity) {
                const s = new ScheduledActivity(scheduledActivity);
                scheduledActivities[scheduledActivityId] = s;
                return s;
            }
            return null;
        }
        else {
            return scheduledActivities[scheduledActivityId]
        }
    }

    const createScheduledActivity = (content: string, dataContext: TData, updateFirebase: boolean, activityId = "", customId = undefined, startTime?: number): ScheduledActivity => {
        const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: customId || activityId || generateId(), content, status: ActivityStatus.NOT_STARTED, activityId, startTime });
        addScheduledActivity(newScheduledActivity);
        if (newScheduledActivity?.startTime) {
            // dataContext.calendarData.addToSchedule(newScheduledActivity.getStartTimeDate(), newScheduledActivity.id, true);
            dataContext.calendarData.addScheduleToDate(newScheduledActivity, convertTimestampToStringDate(newScheduledActivity.startTime))
        }
        if (updateFirebase) {
            newScheduledActivity.updateFirebase(user)
        }
        return newScheduledActivity;
    }

    const addScheduledActivity = (scheduledActivity: ScheduledActivity) => {
        scheduledActivities[scheduledActivity.id] = scheduledActivity;
        refreshScheduledActivities();
    }

    const getSchedule = async (scheduledActivityIds: string[]): Promise<ScheduledActivity[]> => {
        return Promise.all(scheduledActivityIds.map(i => getScheduledActivity(i)))
    }

    return { scheduledActivities, addScheduledActivity, setScheduledActivities, getScheduledActivity, getSchedule, updateScheduledActivity, createScheduledActivity, deleteScheduledActivity, refreshScheduledActivities }

}