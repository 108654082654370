import React from 'react';
import { useState, useEffect } from 'react';
import { useCalendarData } from './components/activity_generator/UseCalendarData';
import { useActivities } from './data/activity/useActivities';
import { useScheduledActivities } from './data/scheduledActivity/useScheduledActivity';
import { useTags } from './hooks/useTags';
import { useModules } from './module/useModules';
import { getUser } from './utils/Firebase';
import { breakpoints, breakpointTypes } from './utils/constants';
import { TData } from './app/interface';

interface TUseData {
    dataContext: any,
    appContext: any,
    mock?: boolean
}

// export const breakpoints = { lg: 960, sm: 768 };

// export const cols = { lg: 24, sm: 2 }

interface TUseDataProps {
    appContextDefaults?: any;
    dataContextDefaults?: any;
    isMobile?: boolean;
    width?: number;
    mockUser?: string;
}

const useData = (props: TUseDataProps): TUseData => {
    const { appContextDefaults = undefined, dataContextDefaults = undefined, mockUser = "" } = props;
    const [rootLayoutId, setRootLayoutId] = useState<string>(appContextDefaults?.rootLayoutId || "");
    const [user, setUser] = useState<string>();
    const [startLayoutId, setStartLayoutId] = useState<string>(appContextDefaults?.startLayoutId || "");
    const [currentBreakpoint, setCurrentBreakpoint] = useState<breakpointTypes>(appContextDefaults?.currentBreakpoint);
    const [mainWidth, setMainWidth] = useState(0);
    const [grids, setGrids] = React.useState({});
    const [dragged, setDragged] = React.useState();
    const [itemsAreContainerDroppable, setItemsAreContainerDroppable] = React.useState(false);

    const scheduledActivities = useScheduledActivities(user);
    const calendarData = useCalendarData(user);
    const activities = useActivities(user);
    const [lockedModules, setLockedModules] = React.useState(false);
    const [forceUpdateLayout, setForceUpdateLayout] = React.useState("")
    const tags = useTags(user)
    // const layout = useLayout(user, dataContextDefaults?.layout);
    const modules = useModules(dataContextDefaults?.modules);
    const dataContext: TData = { modules, activities, calendarData, tags, scheduledActivities } as unknown as TData
    const appContext = { user, setUser, rootLayoutId, setRootLayoutId, mainWidth, setMainWidth, currentBreakpoint, setCurrentBreakpoint, lockedModules, setLockedModules, grids, setGrids, forceUpdateLayout, setForceUpdateLayout, dragged, setDragged, itemsAreContainerDroppable, setItemsAreContainerDroppable }

    useEffect(() => {
        setUser(appContextDefaults?.user)
    }, [appContextDefaults?.user])

    useEffect(() => {
        // if (mockUser) {
        //     setUser(mockUser)
        //     // setStartLayoutId("testStartLayoutId")
        // }
        // else {
        if (!appContextDefaults?.user) {
            getUser().then((user: string) => {
                setUser(user)
            })
        }
        // }
    }, [])

    useEffect(() => {
        if (mainWidth >= breakpoints.lg) {
            setCurrentBreakpoint(breakpointTypes.LG)
        }
        else {
            setCurrentBreakpoint(breakpointTypes.SM)
        }
    }, [mainWidth]);

    return { dataContext, appContext }

}

export { useData };