import * as React from 'react'
import { MinusIcon } from '../../icons/MinusIcon'
import { PlusIcon } from '../../icons/PlusIcon'
import { Input } from '../input/Input'
import { Row } from '../layout/row/Row'

interface INumberStepper {
    value: number;
    step: number;
    onChange: Function;
}

const NumberStepper = ({ value, step, onChange }: INumberStepper) => {

    return (
        <Row className="number-stepper" dataTestid="number-stepper">
            <MinusIcon onClick={() => {
                const subtractedValue = typeof value === "string" ? (parseInt(value) - step) : value - step;
                onChange(subtractedValue)
            }} />
            <Input small value={value.toString()} onChange={onChange} label={''} />
            <PlusIcon onClick={() => {
                const subtractedValue = typeof value === "string" ? (parseInt(value) + step) : value + step;
                onChange(subtractedValue)
            }} />
        </Row>
    )
}

export { NumberStepper }