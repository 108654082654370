import * as React from 'react';
import { AppContext } from '../../../app/AppContext';
import "./PageLoader.scss";
import { MarginRight } from '../../layout/margin/MarginRight';
import { DataContext } from '../../../app/DataContext';
import { ReactGridModule } from '../../../module/modules/ReactGridModule/ReactGridModule';
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { removeModuleFromRootLayout } from '../../../app/utils';
import { ModuleRelations } from '../../../module/modules/ActivityTableModule/ModuleRelations';

const PageLoader = () => {
    const dataContext = React.useContext(DataContext);
    const appContext = React.useContext(AppContext);
    const [startModule, setStartModule] = React.useState<ReactGridModule>();

    React.useEffect(() => {
        if (startModule && appContext) {
            console.log("change", appContext.currentBreakpoint)
            startModule.currentBreakpoint = appContext.currentBreakpoint;
            startModule.forceRender();
        }
    }, [appContext?.currentBreakpoint])

    React.useEffect(() => {
        initLayout();
        initScheduling();
    }, [])

    const initLayout = async () => {
        const rootModule: ReactGridModule = await dataContext.modules.getModule("LayoutStartPoint", dataContext, appContext, true) as ReactGridModule;
        if (rootModule) {
            const ids = rootModule.layout.getGridIds();
            const modules = await dataContext.modules.getModules(ids, dataContext, appContext, true);
            modules.forEach(m => {
                m.onDelete = (self: ModuleRelations) => {
                    removeModuleFromRootLayout(dataContext, appContext, self, false);
                    dataContext.modules.deleteModule(self);
                    self.deleteFromFirebase();
                }
                if ("parentModule" in m) {
                    m.parentModule = rootModule;
                }
            })
            rootModule.childModules = modules;
            rootModule.currentBreakpoint = appContext.currentBreakpoint;
            setStartModule(rootModule);
        }
    }

    const initScheduling = async () => {
        
    }

    return (
        <div className="page-container" data-testid="page-container">
            <MarginRight />
            <MarginBottom />
            {startModule && <div>{startModule.renderModule()}</div>}
        </div>
    )
}

export { PageLoader }