// import React, { useState } from "react";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

// // CSS Modules, react-datepicker-cssmodules.css
// // import 'react-datepicker/dist/react-datepicker-cssmodules.css';

// const DateP = ({ initialDate, onSelect }: { initialDate?: Date, onSelect: Function }) => {
//     const [startDate, setStartDate] = useState(initialDate || new Date());

//     return (
//         <DatePicker selected={startDate} onSelect={date => {
//             onSelect(date);
//         }} onChange={(date) => {
//             setStartDate(date);
//         }}
//         />
//     );
// };

// export { DateP as DatePicker }
import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DP } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

interface ITimePicker {
    onChange: (date: Date) => void;
    defaultTime?: number;
}

const DatePicker = ({ onChange, defaultTime }: ITimePicker) => {
    const [value, setValue] = React.useState<Date | null>(defaultTime ? new Date(defaultTime) : null);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DP data-testid="date-picker"
                label="Välj datum"
                onChange={(newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
                value={value} />
        </LocalizationProvider>
    );
}
export { DatePicker }