import * as React from 'react';
import { Edit } from '@mui/icons-material';

const EditIcon = ({ onClick = null, className = "" }) => {
    return <Edit className={`edit-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { EditIcon };
