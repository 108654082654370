import * as React from 'react';
import { MinimizeRounded } from "@mui/icons-material";


const MinimizeIcon = ({ onClick = null, className = "", color = "" }) => {
    return <MinimizeRounded style={{ color }} className={`minus-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { MinimizeIcon };
