import * as React from 'react'
import { useContext, useEffect, useState } from 'react';
import { format, getMonth, isToday, isWeekend as dateIsWeekend } from 'date-fns'
import { ICalendarEvent, IDate, ILog } from './Calendar';
import CalendarContext, { IHoliday } from './CalendarContext';
import Modal from '../../components/modal/Modal';
import { convertDateObjectToString, convertTimestampToStringDateAndTime, getNow } from "../../utils/TimeUtils"
import StringIcons, { IconTypes } from '../string_icons/StringIcons';
import { generateId, getItemWithMostOccurences } from '../../utils/Utils';
import { AppContext } from '../../app/AppContext';
import { ActivityStatus, weatherSymbolNameSwe } from '../../utils/constants';
import { SunIcon } from '../../icons/SunIcon';
import { Row } from '../layout/row/Row';
import { Button } from '../button/Button';
import { EditIcon } from '../../icons/EditIcon';
import { IconPicker } from '../pickers/icon_picker/IconPicker';
import { ExpansionPanel } from '../expander/Expander';
import { TextArea } from '../textarea/TextArea';
import { DataContext } from '../../app/DataContext';
import { ActivityPicker } from '../pickers/activity_picker/ActivityPicker';
import { Activity } from '../../data/activity/Activity';
import { Input } from '../input/Input';
import { Column } from '../layout/column/Column';
import { CloseIcon } from '../../icons/CloseIcon';
import { ScheduledActivity } from '../activity_generator/ScheduledActivity';
import { scheduleActivity as sa} from "../../app/utils";

export const getDefaultCurrentContent = (date: string): IDate => ({
    date, data: {
        icons: [], logs: []
    }, schedule: []
})

const CalendarCard = ({ date, greyedOut = false, monthLabel, weekdayLabel }) => {
    const appContext = React.useContext(AppContext)
    const dataContext = React.useContext(DataContext)
    const { data, setData, setDate, selectedDate, setSelectedDate, weatherForecast } = useContext(CalendarContext);
    const highlight = isToday(date.getTime())
    const [edit, setEdit] = useState(false);
    const [currentContent, setCurrentContent] = useState<IDate>()
    const [showModal, setShowModal] = useState(false);
    const [holiday] = useState<IHoliday>()
    const [onHover, setOnHover] = useState(false);
    const [calendarEvent, setCalendarEvent] = useState<string>();
    const [scheduleActivity, setScheduleActivity] = React.useState(false);
    const [createMetricsPoint, setCreateMetricsPoint] = React.useState(false);
    const [titleLog, setTitleLog] = React.useState("");
    const [logContent, setLogContent] = React.useState("");
    const scrollRef = React.useRef(null);

    const isCurrentMonth = getMonth(new Date()) === getMonth(date);

    const getWeatherForecast = () => {
        //@ts-ignore
        const onlyThisDate = weatherForecast.timeSeries
            .filter(time => time.validTime.replace(/-/g, "").includes(convertDateObjectToString(date)))
        return onlyThisDate
    }

    const getWeatherForecastType = (parameter) => {
        const forecast = getWeatherForecast()
        return forecast.map(v => ({ validTime: v.validTime.split("T")[1].split("Z")[0], [parameter]: v.parameters.filter(p => p.name === parameter)[0].values[0] }))
    }

    const getAverageWeather = () => {
        const weatherForecast = getWeatherForecastType("t");
        if (!weatherForecast?.length) {
            return -1;
        }
        if (weatherForecast.length === 1) {
            return weatherForecast[0].temp
        }

        const average = Math.round(weatherForecast.map(x => x.temp).reduce((a, b) => (a + b)) / weatherForecast.length)
        return average
    }

    const getMinMaxWeather = () => {
        const weatherForecast = getWeatherForecastType("t");
        if (!weatherForecast?.length) {
            return -1;
        }
        if (weatherForecast.length === 1) {
            return weatherForecast[0].temp
        }

        const temps = weatherForecast.map(x => x["t"]);
        const min = Math.round(Math.min(...temps))
        const max = Math.round(Math.max(...temps))
        return `${min} - ${max}`
    }

    const stringDate = convertDateObjectToString(date)

    useEffect(() => {
        // initHolidays();
        if (highlight) {
            // scrollRef.current.scrollIntoView()
        }
    }, [])

    useEffect(() => {
        const dateData = getDate(stringDate, data);
        if (dateData) {
            setCurrentContent({ ...getDefaultCurrentContent(stringDate), ...dateData })
        }
        else {
            setCurrentContent(getDefaultCurrentContent(stringDate));
        }
    }, [data, date, stringDate])

    useEffect(() => {
        const setEditable = selectedDate === date;
        setEdit(setEditable);
    }, [selectedDate, date])


    const updateCardFirebaseAndComponentState = (dateObj: IDate) => {
        setCurrentContent(dateObj);
        setEdit(false);
        setDate(dateObj);
        setSelectedDate(null);
        setData([...data]);
    }

    const renderModal = () => <Modal showModal={showModal} setShowModal={setShowModal}>
        <CloseIcon onClick={() => setShowModal(false)} />
        <h2>{stringDate}</h2>
        <Row className="margin-bottom"><div className="margin-right">
            <Button label={'Schedulera aktivitet'} onClick={() => {
                setScheduleActivity(true);
            }} /></div>
            <Button label={'Skapa mätpunkt'} onClick={() => {
                setCreateMetricsPoint(true);
            }} />
        </Row>
        <ExpansionPanel className="margin-bottom" summary="Välj ikoner" details={
            <div>
                <IconPicker multiChoice initialIcons={currentContent?.data?.icons || []} onSelect={(pickedIcons) => {
                    if (!currentContent.data.icons) {
                        currentContent.data.icons = [];
                    }
                    currentContent.data.icons = pickedIcons
                    // setCurrentContent({ ...currentContent })
                    updateCardFirebaseAndComponentState({ ...currentContent });
                }} />
                {/* {currentContent.data.icons.map((icon, i) => {
                    const Icon = StringIcons(icon, () => { });
                    return <React.Fragment key={i}><Row>{Icon}</Row></React.Fragment>
                })} */}
            </div>
        } />
        {/* <ExpansionPanel summary={"Kalenderhändelser"} details={
            <div>
                <Row className="margin-bottom">

                    <TextArea value={calendarEvent} className="margin-right" onChange={val => {
                        setCalendarEvent(val);
                    }} />

                    <Button label={'Skapa kalenderhändelse'} onClick={() => {
                        if (currentContent) {
                            if (!currentContent.data) {
                                currentContent.data = { notes: [], events: [], icons: [], logs: [], metrics: {} }
                            }
                            if (!currentContent.data.events) {
                                currentContent.data.events = [];
                            }
                            currentContent.data.events.push({ created: getNow(), content: calendarEvent, finished: false, type: "event" });
                            setCalendarEvent("");
                            updateCardFirebaseAndComponentState({ ...currentContent });
                        }
                    }} />
                </Row>
                <div>{currentContent?.data?.events?.length > 0 && currentContent.data.events.sort((a, b) => a.created > b.created ? -1 : 1).map((event, i) => {
                    return <React.Fragment key={i}>
                        <Row>{renderCalendarEventRow(event)}</Row>
                    </React.Fragment>
                })}</div>
            </div>
        } /> */}

        <ExpansionPanel summary={"Logg"} details={

            <div>
                <Row className="margin-bottom align-top">
                    <Input className="margin-right" value={titleLog} label={'Titel'} onChange={text => setTitleLog(text)} />
                    <TextArea value={logContent} className="margin-right" onChange={text => setLogContent(text)} />

                    <Button label={'Skapa en logg'} onClick={() => {
                        if (currentContent) {
                            if (!currentContent.data) {
                                currentContent.data = {icons: [], logs: [] }
                            }
                            if (!currentContent.data.logs) {
                                currentContent.data.logs = [];
                            }
                            currentContent.data.logs.push({ id: generateId(), created: getNow(), title: titleLog, content: logContent });
                            setTitleLog("");
                            setLogContent("");
                            updateCardFirebaseAndComponentState({ ...currentContent });
                        }
                    }} />
                </Row>
                {currentContent?.data?.logs?.length > 0 && <Column style={{ backgroundColor: 'lightgrey', padding: '16px', alignItems: 'flex-start' }}>
                    {currentContent.data.logs.map((log: ILog, key) => {
                        return <div key={key}>{log.title ? log.title + ":" : ""}{log.content}</div>
                    })}
                </Column>}
                {/* <div>{currentContent?.data?.events?.length > 0 && currentContent.data.events.sort((a, b) => a.created > b.created ? -1 : 1).map((event, i) => {
                    return <React.Fragment key={i}>
                        <Row>{renderCalendarEventRow(event)}</Row>
                    </React.Fragment>
                })}</div> */}
            </div>
        } />
        <Button label={'Stäng'} onClick={() => setShowModal(false)} />
    </Modal>

    const renderIcons = () => <div className="icons">
        {currentContent?.data?.icons?.length > 0 && currentContent?.data?.icons.map((icon, index) => {
            return <React.Fragment key={index}>{StringIcons(icon as IconTypes, () => { })}</React.Fragment>
        })}
        {/* {actionMenuState.find(c => c.name === "Visa checklistor").checked === true && currentContent?.checklists?.length > 0 && <ChecklistIcon onClick={() => {
            setPickChecklist(true);
            // setShowChecklist(currentContent.checklists[0]);
        }} style={{ color: currentContent.checklists.every(c => c.finished) ? 'green' : 'red' }} />} */}

    </div>

    // const renderContent = () => currentContent?.data?.events?.length > 0 && <div className="content">{currentContent.data?.events[0].content}</div>

    const renderDate = () => !edit && <div className="date">
        {/* {isMobile && weekdayLabel + " "} */}
        {format(date, "d")}
        {/* {isMobile && " " + monthLabel} */}
    </div>

    const renderWeatherForecast = () => {
        const minMax = getMinMaxWeather()

        let symbol = getWeatherForecastType("Wsymb2")
        if (symbol) {
            symbol = symbol.map(s => s["Wsymb2"]);
            symbol = getItemWithMostOccurences(symbol)
        }
        if (minMax < 0) {
            return null
        }
        return <Row className="weather"><SunIcon /> {minMax ? minMax : ""}{symbol ? " / " + weatherSymbolNameSwe[symbol] : ""}</Row>
    }

    const renderNamnsdag = () => {
        if (!holiday?.namnsdag) {
            return null;
        }
        return <div className="namnsdag">{holiday.namnsdag.map((namn, index) => {
            return <React.Fragment key={index}>{(index > 0 ? ", " : "") + namn}
            </React.Fragment>
        })}</div>
    }

    const isWeekend = dateIsWeekend(date)

    const renderHelgdag = () => {
        if (!holiday?.helgdag) {
            return null;
        }
        return <div className="holiday">{holiday.helgdag}</div>
    }

    return (
        <div
            onMouseEnter={() => {
                setOnHover(true);
            }}
            onMouseLeave={() => {
                setOnHover(false);
            }}
            ref={scrollRef}
            className={`calendar-card
            ${onHover ? " hover" : ""}            
            ${highlight ? " highlighted" : ""}
            ${greyedOut ? " greyed-out" : ""}
            ${currentContent?.schedule ? " calendar-card--schedule" : ""}
            ${isWeekend ? " helgdag" : ""}
            // ${holiday && (holiday.veckodag !== "Lördag" && holiday.veckodag !== "Söndag") && holiday['röd dag'] === "Ja" ? " röd-veckodag" : ""}
            ${!isCurrentMonth ? " date-outside-month" : ""}
        `}
        >
            {renderDate()}
            {/* {renderNamnsdag()} */}
            {renderHelgdag()}
            {renderIcons()}
            {/* {actionMenuState?.find(c => c.name === "Visa kalenderhändelser")?.checked === true && renderContent()}
            {actionMenuState?.find(c => c.name === "Visa väder")?.checked === true && renderWeatherForecast()}
            {actionMenuState?.find(c => c.name === "Visa ikoner")?.checked === true && renderIcons()}
            
            {actionMenuState?.find(c => c.name === "Visa måltider")?.checked === true && objectHasProperties(currentContent?.data?.meals) && <FoodIcon />} */}
            {onHover && <EditIcon onClick={() => {
                setShowModal(true);
                setOnHover(false)
            }} />}
            {scheduleActivity && <Modal showModal={scheduleActivity} setShowModal={setScheduleActivity}>
                <ActivityPicker
                    activities={dataContext.activities}
                    showCreateQuickActivity
                    onSelect={(activity: Activity) => {
                        // const stringDate = convertDateObjectToString(date);
                        // calendarData.scheduleQuickScheduledActivity(activity.name, stringDate, activity.defaultTimer, user, activity.id, dataContext);
                        // setScheduleActivity(false);
                    }}
                    onQuickCreate={async (activity: Activity) => {
                        const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), content: activity.name, status: ActivityStatus.NOT_STARTED, activityId: activity.id, interval: activity.interval });
                        
                        sa(dataContext, appContext, newScheduledActivity, appContext.user, currentContent.date);
                        // const calendarData: CalendarData = await dataContext.calendarData.getCalendarDate(currentContent.date, true, false);
                        // calendarData.schedule.push(newScheduledActivity.id);
                        
                        // calendarData.updateFirebase(user);
                        // newScheduledActivity.updateFirebase(user);

                        // currentContent.schedule.push(newScheduledActivity);
                        // currentContent.schedule.push(newScheduledActivity.id);
                        // this.onTemporaryScheduledActivityCreated(newScheduledActivity, currentContent);
                        // this.closeModal();
                        setShowModal(undefined);


                        // this.onScheduledActivityUpdate(newScheduledActivity, cd.calendarData);

                        // const stringDate = convertDateObjectToString(date);
                        // calendarData.scheduleQuickScheduledActivity(activity.name, stringDate, activity.defaultTimer, user, activity.id, dataContext);
                    }} />
            </Modal>}
            {createMetricsPoint && <Modal showModal={createMetricsPoint} setShowModal={setCreateMetricsPoint}>
                {/* <MetricsCreator date={convertTimestampToStringDate(date.getTime())} /> */}
            </Modal>}
            {renderModal()}
        </div >
    )
}

export const renderCalendarEventRow = (calendarEvent: ICalendarEvent) => {
    return <div className="calendar-event-row">{`${convertTimestampToStringDateAndTime(calendarEvent.created)}: ${calendarEvent.content}`}</div>
}

export const getDate = (stringDate, data) => data?.length ? data.find(dateObj => dateObj.date === stringDate) : undefined;

export { CalendarCard }
