import * as React from 'react';
import {QuestionMark} from '@mui/icons-material';

const QuestionMarkIcon = ({ onClick = null, className = "" }) => {
    return <QuestionMark className={`question-mark-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { QuestionMarkIcon };
