import { ActivityStatus, LogTypeEnums } from './../../utils/constants';
import { getToday, getStringDatesBetween, convertTimestampToStringDate } from './../../utils/TimeUtils';
import { clearObjectOfUndefines, getRandomId } from './../../utils/Utils';

import * as React from 'react';
import { useState } from 'react'
import { firebaseModulePaths } from '../../utils/constants';
import { readData, writeData } from '../../utils/Firebase';
import { flattenYearData as firebaseFlattenYearData } from '../../utils/FirebaseCalendarUtils';
import { timeUnitConverterMinutsHoursDays } from '../../utils/TimeUtils';
import { ScheduledActivity } from './ScheduledActivity';
import { DataContext } from '../../app/DataContext';
import { Activity } from '../../data/activity/Activity';
import { CardModule } from '../../module/modules/CardModule/CardModule';
import { CalendarData, IEventLog, getDefaultCalendarData } from '../calendar/CalendarData';
import { TCalendarData, TData } from '../../app/interface';

const useCalendarData = (user): TCalendarData => {
    const dataContext = React.useContext(DataContext);
    const [calendarData, setCalendarData] = useState<CalendarData[]>([]);

    const updateCalendarData = () => {
        setCalendarData([...calendarData]);
    }

    const flattenYearData = (firebaseCalendarData): CalendarData[] => {
        let data: CalendarData[] = [];
        Object.values(firebaseCalendarData).forEach((year) => {
            const flattenedData = firebaseFlattenYearData(year);
            data = [...data, ...flattenedData]
        })
        return data;
    }

    // const scheduleActivity = (name: string, duetime: number, scheduledActivityId?: Activity): ScheduledActivity => {
    //     const stringDate = convertTimestampToStringDate(duetime);
    //     // const scheduledActivity = new ScheduledActivity({ id: getRandomId(), date: stringDate, content: name, status: ActivityStatus.NOT_STARTED, dueTime: duetime });
    //     // scheduledActivity.updateFirebase(user);
    //     // clearObjectOfUndefines(scheduledActivity);
    //     // if (activity) {
    //     //     scheduledActivity.activityId = activity.id;
    //     // }
    //     addToSchedule(stringDate, scheduledActivity, true);
    //     return scheduledActivity;
    // }

    const convertCardsToSchedule = (cards: CardModule[]): ScheduledActivity[] => {
        const schedule = [];
        if (!cards?.length) {
            return schedule;
        }
        cards.forEach((card, index) => {
            // const data: ScheduledActivity = new ScheduledActivity({ id: card.id, ...card?.extra?.card })
            // schedule.push(data);
        })
        return schedule;
    }

    const convertBoardToCalendarData = (board) => {
        // const schedule = convertCardsToSchedule(board.cards)
        // const calendarData: CalendarData = { id: board.id, date: "fel datum", data: board?.extra?.data || {}, schedule }
        return null;
        // return calendarData
    }

    const convertBoardsToDailySchedules = (boards: []): CalendarData[] => {
        const dailySchedules: CalendarData[] = [];
        boards.forEach((board, boardIndex) => {
            const calendarData = convertBoardToCalendarData(board);
            dailySchedules.push(calendarData);
        })
        return dailySchedules;
    }

    const setCalendarDataFromBoards = (boards: []): CalendarData[] => {
        const calendarData: CalendarData[] = convertBoardsToDailySchedules(boards)
        setCalendarData(calendarData);
        return calendarData;
    }

    // const registerMerchandisePurchase = async (time: number, merchandiseId: string) => {
    //     const stringDate = convertTimestampToStringDate(time);
    //     const calendarDate: CalendarData = await getCalendarDate(stringDate);
    //     if (!calendarDate.data) {
    //         calendarDate.data = { icons: [], events: [], notes: [], logs: [], metrics: {} }
    //     }
    //     if (!calendarDate.data.purchases) {
    //         calendarDate.data.purchases = [];
    //     }
    //     calendarDate.data.purchases.push({ merchandiseId, time });
    //     synkFirebaseCalendarDataCalendarData(calendarData);
    // }

    const synkFirebaseCalendarDataCalendarData = async (newCalendarData: CalendarData[]) => {
        for (const weekday of newCalendarData) {
            if (weekday?.date) {
                synkFirebaseCalendarDateData(weekday)
            }
        }
    }

    const synkFirebaseCalendarDateData = async (newCalendarData: CalendarData) => {
        const p = firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, newCalendarData.date);
        clearObjectOfUndefines(newCalendarData);
        await writeData(p, newCalendarData.convertToFirebaseObject());
    }

    const getDatesWithSchedule = () => {
        if (calendarData) {
            return calendarData.filter((c: CalendarData) => c.schedule?.length)
        }
        return []
    }

    const setScheduledActivity = (date: string, scheduledActivity: ScheduledActivity): CalendarData[] => {
        const calendarDate: CalendarData = calendarData.find((calendarData: CalendarData) => calendarData.date === date)
        if (calendarDate?.schedule?.length && scheduledActivity) {
            // const i = calendarDate.schedule.findIndex((s: ScheduledActivity) => s.id === scheduledActivity.id)
            calendarDate.schedule[scheduledActivity.id] = scheduledActivity;
            updateCalendarData();
        }
        return calendarData;
    }

    const setCalendarDate = (calendarDate: CalendarData, update: boolean) => {
        const currentIndex = calendarData.findIndex((c: CalendarData) => c.date === calendarDate.date);
        if (currentIndex > -1) {
            calendarData[currentIndex] = calendarDate;
        }
        else {
            calendarData.push(calendarDate);
        }
        update && updateCalendarData();
    }

    const getStarttimesForActivity = async (activityId: string): Promise<number[]> => {
        const startTimes = [];
        const datesWithSchedules: CalendarData[] = getDatesWithSchedule();
        // datesWithSchedules.forEach((d: CalendarData) => {
        for (const d of datesWithSchedules) {
            const schedule = await d.getSchedule(dataContext)
            schedule.forEach((s: ScheduledActivity) => {
                if (s?.extra?.activityId === activityId) {
                    if (s.lastStartTime) {
                        startTimes.push(s.lastStartTime);
                    }
                }
            })
        }
        return startTimes
    }

    const getAverageTimeBetweenActivityStarts = async (activityId) => {
        const startTimes: number[] = await getStarttimesForActivity(activityId);
        const millisBetweenStarttimes = startTimes.sort((a, b) => a > b ? 1 : -1).map((startTime: number, i: number) => {
            if (i < startTimes.length - 1) {
                return startTimes[i + 1] - startTimes[i];
            }
        }).filter(v => v)
        if (!millisBetweenStarttimes?.length) {
            return -1;
        }
        const millisFromLastStarttimeToNow = new Date().getTime() - startTimes[startTimes.length - 1];
        millisBetweenStarttimes.push(millisFromLastStarttimeToNow);
        const average = millisBetweenStarttimes.reduce((a, b) => (a + b), 0) / millisBetweenStarttimes.length

        if (millisFromLastStarttimeToNow > average) {
            millisBetweenStarttimes.push(millisFromLastStarttimeToNow);
            const recalulatedAverage = millisBetweenStarttimes.reduce((a, b) => (a + b), 0) / millisBetweenStarttimes.length
            const t = timeUnitConverterMinutsHoursDays(recalulatedAverage)
            return t.value + t.unit;
        }
        else {
            const t = timeUnitConverterMinutsHoursDays(average)
            return t.value + t.unit;
        }
    }

    const addDateToCalendar = async (calendarDate: CalendarData, update: boolean, updateFirebase: boolean) => {
        calendarData.push(calendarDate);
        update && updateCalendarData();
        if (updateFirebase) {
            calendarDate.updateFirebase(user);
            synkFirebaseCalendarDateData(calendarDate);
        }
    }

    const getCalendarDate = async (date: string, createIfDoesntExist: boolean, updateCalendarData): Promise<CalendarData> => {
        let foundCalendarData = calendarData.find(c => c.date === date);
        if (!foundCalendarData) {
            foundCalendarData = await readData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, date));
            if (foundCalendarData) {
                foundCalendarData = new CalendarData(foundCalendarData);
            }
        }
        if (!foundCalendarData && createIfDoesntExist) {
            foundCalendarData = new CalendarData(getDefaultCalendarData(date))
        }
        updateCalendarData && setCalendarDate(foundCalendarData, updateCalendarData);
        return foundCalendarData;
    }

    const deleteScheduledActivity = async (date: string, scheduledActivityId: string) => {
        if (date) {
            const calendarDate = await getCalendarDate(date, true, true);
            if (calendarDate) {
                calendarDate.schedule = calendarDate.schedule.filter(s => s !== scheduledActivityId)
                updateCalendarData();
                synkFirebaseCalendarDataCalendarData(calendarData);
            }
        }
    }

    const addScheduleToDate = async (scheduledActivity: ScheduledActivity, date: string) => {
        const cd = await getCalendarDate(date, true, true);
        if (!cd.schedule.includes(scheduledActivity.id)) {
            cd.schedule.push(scheduledActivity.id);
            updateCalendarData();
        }

    }

    // const addToSchedule = (date: string, scheduledActivityId: string, updateFirebase = false) => {
    //     let cal: CalendarData = calendarData?.find(cal => cal.date === date);
    //     if (!cal) {
    //         cal = new CalendarData(getDefaultCalendarData(date))
    //         calendarData.push(cal);
    //     }
    //     if (!cal.schedule) {
    //         cal.schedule = [];
    //     }
    //     if (!cal.schedule?.includes(scheduledActivityId)) {
    //         cal.schedule.push(scheduledActivityId);
    //         clearObjectOfUndefines(cal);
    //         const index = calendarData.findIndex(c => c.date === date);
    //         calendarData[index] = cal;
    //         updateCalendarData();
    //         if (updateFirebase) {
    //             synkFirebaseCalendarDateData(cal);
    //         }
    //     }
    // }

    const moveScheduledActivityToAnotherDate = (scheduledActivity: ScheduledActivity, fromDate: string, toDate: string) => {
        fromDate && deleteScheduledActivity(fromDate, scheduledActivity.id);
        toDate && addScheduleToDate(scheduledActivity, toDate);
    }

    const updateScheduledActivity = async (scheduledActivity: ScheduledActivity, oldDate: string, newDate: string) => {

        if (oldDate !== newDate) {
            moveScheduledActivityToAnotherDate(scheduledActivity, oldDate, newDate);
        }
        else {
            const calendarDate = await getCalendarDate(newDate, true, false);
            if (!calendarDate.schedule.includes(scheduledActivity.id)) {
                calendarDate.schedule.push(scheduledActivity.id);
            }
        }

        updateCalendarData();
    }

    // const addScheduledActivity = (date: string, scheduledActivity: ScheduledActivity): CalendarData[] => {
    //     let calendarDate: CalendarData = calendarData.find((calendarData: CalendarData) => calendarData.date === date)
    //     if (!calendarDate) {
    //         const newCalendarData: CalendarData = { id: generateId(), date, schedule: {}, data: { icons: [], events: [], notes: [], meals: {} } }
    //         calendarData.push(newCalendarData);
    //         calendarDate = newCalendarData;
    //     }
    //     if (!calendarDate?.schedule) {
    //         calendarDate.schedule = {};
    //     }
    //     if (scheduledActivity) {
    //         calendarDate.schedule[scheduledActivity.id] = scheduledActivity;
    //         updateCalendarData();;
    //     }
    //     return calendarData;
    // }


    // const updateCalendarDate = (calendarDate: CalendarData) => {
    //     const i = calendarData.findIndex(c => c.date === calendarDate.date);
    //     calendarData[i] = { ...calendarDate }
    //     updateCalendarData();
    // }

    // const updateCalendarDates = (calendarDates: CalendarData[]) => {
    //     calendarDates.forEach((cd: CalendarData) => {
    //         const index = calendarData.findIndex(c => c.id === cd.id);
    //         if (index > -1) {
    //             calendarData[index] = { ...cd }
    //         }
    //     })
    //     updateCalendarData();
    // }

    const addCardToSchedulePlanner = (scheduledActivity: ScheduledActivity, date: string, deleteFunction: Function) => {
        let calendarDate: CalendarData = calendarData.find((calendarData: CalendarData) => calendarData.date === date)
        if (!calendarDate) {
            const newCalendarData: CalendarData = new CalendarData(getDefaultCalendarData(date))
            calendarData.push(newCalendarData);
            calendarDate = newCalendarData;
        }
        if (!calendarDate?.schedule) {
            calendarDate.schedule = [];
        }
        if (scheduledActivity) {
            calendarDate.schedule[scheduledActivity.id] = scheduledActivity;
            updateCalendarData();
            synkFirebaseCalendarDateData(calendarDate);
        }
        if (deleteFunction) {
            deleteFunction();
        }
    }

    const createNewCalendarDate = (date: string): CalendarData => {
        const newCalendarDate: CalendarData = new CalendarData(getDefaultCalendarData(date));
        calendarData.push(newCalendarDate);
        updateCalendarData();
        return newCalendarDate
    }

    const getTotalPointsFinishedSoFarForActivityCategory = async (date: string, activities: Activity[], parentActivities: Activity[]): Promise<number> => {
        const calendarData: CalendarData = await getCalendarDate(date, true, true);
        const schedule = await calendarData?.getSchedule(dataContext)
        const totalTime = schedule?.reduce((previous, current: ScheduledActivity): any => {
            if (current.status === ActivityStatus.DONE) {
                return previous + current.totalTime;
            }
            else {
                return previous;
            }
        }, 0) || 0;
        return totalTime;
    }

    // const getTotalEstimatedTimeForActivityCategory = (date: string, activities: Activity[], parentActivities: Activity[]): number => {
    //     const calendarData: CalendarData = getCalendarDate(date);
    //     const totalTime = Object.values(calendarData?.schedule)?.reduce((previous, current: ScheduledActivity): any => {
    //         const activityId = current?.extra?.activityId;
    //         const activity: Activity = activities.find(a => a.id === activityId && parentActivities.every(pa => a.hasAncestor(pa)));

    //         const averageTime = activity?.averageTime || 0;
    //         return previous + averageTime;
    //     }, 0) || 0;
    //     return totalTime;
    // }

    const getDates = async (startDate: string, endDate: string, fillEmptyDates: boolean, updateCalendar: boolean = false): Promise<CalendarData[]> => {
        const dates: CalendarData[] = []
        const stringDates = getStringDatesBetween(startDate, endDate)

        for (const date of stringDates) {
            const d = await getCalendarDate(date, fillEmptyDates, false);
            if (d) {
                dates.push(d);
            }
        }
        if (updateCalendar) {
            dates.forEach(d => setCalendarDate(d, false))
            updateCalendarData();
        }
        return dates;
    }

    const getScheduledActivitiesInTimespan = async (startDate: string, endDate: string): Promise<string[]> => {
        const dates = await getDates(startDate, endDate, false);
        return dates.reduce((previousValue, currentValue, currentIndex, array) => {
            const newScheds = Object.values(currentValue.schedule);
            return [...previousValue, ...newScheds];
        }, []);
    }

    const getLatestActivitiesInTimeSpan = async (startDate: string, endDate: string, filterFinished = true): Promise<ScheduledActivity[]> => {
        const dates = await getDates(startDate, endDate, false);
        const scheduledActivities = dates.reduce((previousValue, currentValue, currentIndex, array) => {
            if (currentValue.hasSchedule()) {
                return [...previousValue, ...Object.values(currentValue.schedule)];
            }
            return previousValue;
        }, [])
        scheduledActivities.sort((a, b) => a.date > b.date ? -1 : 1);
        if (filterFinished) {
            return scheduledActivities.filter(s => s.isFinished())
        }
        return scheduledActivities;
    }

    const getAllScheduledActivitiesOfTypeInTimeSpan = async (searchActivityName: string, startDate: string, endDate: string, dataContext: TData, fillEmptyDates: boolean, updateCalendar: boolean): Promise<ScheduledActivity[]> => {
        let foundActivities = [];
        const dates = await getDates(startDate, endDate, fillEmptyDates, updateCalendar);
        // if(searchActivityName.includes("Jogga")){
        //     console.log(dates);
        // }
        // dates.forEach((calendarDate: CalendarData) => {
        for (const calendarDate of dates) {
            const schedule: ScheduledActivity[] = await dataContext.scheduledActivities.getSchedule(calendarDate.schedule);
            // calendarDate.getSchedule(dataContext)?.forEach((scheduledActivity: ScheduledActivity) => {
            //     if (scheduledActivity.content === searchActivityName) {
            //         foundActivities.push(scheduledActivity);
            //     }
            // })
            const activitiesWithName = schedule?.filter(s => s?.content === searchActivityName);
            foundActivities = [...foundActivities, ...activitiesWithName];
        }
        if (foundActivities?.length) {
            foundActivities.sort((a, b) => a.date > b.date ? -1 : 1)
        }
        return foundActivities.sort((a, b) => a.date > b.date ? -1 : 1);
    }

    const getLatesScheduledActivityOfTypeInTimeSpan = async (searchActivity: string, startDate: string, endDate: string, dataContext: TData, fillEmptyDates: boolean, updateCalendar: boolean): Promise<ScheduledActivity> => {
        const allActivititesOfType: ScheduledActivity[] = await getAllScheduledActivitiesOfTypeInTimeSpan(searchActivity, startDate, endDate, dataContext, fillEmptyDates, updateCalendar);
        if (allActivititesOfType?.length) {
            allActivititesOfType.sort((a, b) => a.dueTime > b.dueTime ? -1 : 1);
            return allActivititesOfType[0]
        }
        return null;
    }
    
    const scheduleScheduledActivity = async(scheduledActivity: ScheduledActivity, date: string) => {
        console.log(scheduleScheduledActivity);
        console.log(date);
        // const date = convertTimestampToStringDate(scheduledActivity.startTime);
        let calendarDate: CalendarData = await getCalendarDate(date, true, false);
        if (!calendarDate.schedule) {
            calendarDate.schedule = [];
        }
        calendarDate.schedule.push(scheduledActivity.id)
        updateCalendarData();
    }

    const createEventLog = async (title: string, content: string, type: LogTypeEnums, updateFirebase: boolean, extra: any = {}): Promise<IEventLog> => {
        const calendarDate = await getCalendarDate(getToday(), true, false);
        const newEventLog: IEventLog = { id: getRandomId(), created: new Date().getTime(), title, content, type, extra }

        if (!calendarDate.applicationEventLog) {
            calendarDate.applicationEventLog = [];
        }
        calendarDate.applicationEventLog.push(newEventLog);
        if (updateFirebase) {
            calendarDate.updateFirebase(user);
        }
        updateCalendarData()
        return newEventLog;
    }

    const getEventLogs = async (fromDate: string, toDate: string): Promise<IEventLog[]> => {
        const dates = await getDates(fromDate, toDate, true, true)
        const logs = dates.reduce((previousValue, currentValue, currentIndex, array) => {
            if (currentValue.applicationEventLog) {
                return [...previousValue, ...currentValue.applicationEventLog];
            }
            return previousValue;
        }, [])
        return logs
    }

    return { calendarData, setCalendarData, getAverageTimeBetweenActivityStarts, setScheduledActivity, setCalendarDataFromBoards, synkFirebaseCalendarDataCalendarData, convertBoardsToDailySchedules, updateScheduledActivity, flattenYearData, addCardToSchedulePlanner, getCalendarDate, getTotalPointsFinishedSoFarForActivityCategory, getAllScheduledActivitiesOfTypeInTimeSpan, synkFirebaseCalendarDateData, convertBoardToCalendarData, setCalendarDate, getDates, getLatesScheduledActivityOfTypeInTimeSpan, moveScheduledActivityToAnotherDate, deleteScheduledActivity, getLatestActivitiesInTimeSpan, getScheduledActivitiesInTimespan, addDateToCalendar, updateCalendarData, createEventLog, addScheduleToDate, getEventLogs, scheduleScheduledActivity }
}

export { useCalendarData }
