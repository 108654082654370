import { writeData } from "../../utils/Firebase";

// interface ISetting {
//     [key: string]: any;
// }

export interface ISettings {
    path: string;
    [key: string]: any;
}

export class Settings implements ISettings {
    path: string;
    [key: string]: any;

    constructor(path: string) {
        this.path = path;
    }

    setSetting(key: string, setting: any, updateFirebase: boolean = true) {
        this[key] = setting;
        if (updateFirebase) {
            this.updateFirebase();
        }
    }

    getSetting(key: string) {
        return this[key];
    }

    updateFirebase() {
        console.log(this.path, this);
        writeData(this.path, this);
    }
}

