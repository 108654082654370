import * as React from 'react';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

const ClockIcon = ({ onClick = null, className = "" }) => {
    return <AlarmOnIcon className={`clock-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { ClockIcon };
