import * as React from 'react'
import { isMonday } from 'date-fns'
import { CalendarWeek, getWeekNumber } from './CalendarWeek'

const CalendarMonth = ({ displayedDates, today }) => {


    const splitToWeeks = () => {

        const findFirstMonday = () => {
            const firstMonday = displayedDates.find(date => isMonday(date))
            return firstMonday;
        }

        if (displayedDates?.length) {
            const firstMonday = findFirstMonday()
            const idxOfFirstMonday = displayedDates.indexOf(firstMonday);
            const firstWeek = displayedDates.slice(0, idxOfFirstMonday);
            const restOfDays = displayedDates.slice(idxOfFirstMonday, displayedDates.length)
            const weeksInMonth = []
            if (firstWeek?.length > 0) {
                weeksInMonth.push(firstWeek);
            }
            let i, j, temporary, chunk = 7;
            for (i = 0, j = restOfDays.length; i < j; i += chunk) {
                temporary = restOfDays.slice(i, i + chunk);
                if (temporary?.length > 0) {
                    weeksInMonth.push(temporary);
                }
            }
            return weeksInMonth;
        }
    }

    const renderWeekHeader = (week, isFirstWeek, hidden, index) => <div className="schedule-link">
        {/* <Link to={{ pathname: `/${pages.SCHEDULE_WEEK}`, state: { week, isFirstWeek, pageType, data } }} >V. {getWeekNumber(week)}</Link> */}
        <h1 className="calendar-month__header">V. {getWeekNumber(week)}</h1>
        {/* {!hidden && <MinimizeIcon color="fff" onClick={() => {
            if (!hidden) {
                minimizedWeeks.push(index);
                setMinimizedWeeks([...minimizedWeeks])
            }
        }} />}
        {hidden && <PlusIcon color="fff" onClick={() => {
            if (hidden) {
                const i = minimizedWeeks.findIndex(m => m === index)
                removeAtIndex(minimizedWeeks, i);
                setMinimizedWeeks([...minimizedWeeks])
            }
        }} />} */}
    </div>

    const renderWeeks = () => {
        const weeks = splitToWeeks();
        return (<div className="calendar-month">
            {weeks?.length && weeks.map((week, index) => {
                const hidden = false//minimizedWeeks.includes(index)
                const isFirstWeek = index === 0;
                if (hidden) {
                    return renderWeekHeader(week, isFirstWeek, hidden, index)
                }
                return <div key={index} className={`calendar-week-container${hidden ? " hidden" : ""}`}>
                    <div className="calendar-week" key={index}>
                        {renderWeekHeader(week, isFirstWeek, hidden, index)}
                        {<CalendarWeek month={today?.getMonth()} week={week} isFirstWeek={isFirstWeek} />}
                    </div>
                </div>
            })}
        </div>)
    }

    return (
        <React.Fragment>
            {renderWeeks()}
        </React.Fragment>
    )
}

export { CalendarMonth }
