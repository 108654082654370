import { ScheduledActivity } from "../components/activity_generator/ScheduledActivity";
import { ModuleRelations } from "../module/modules/ActivityTableModule/ModuleRelations";
import { BaseModule, IBaseModule } from "../module/modules/BaseModule";
import { ReactGridModule } from "../module/modules/ReactGridModule/ReactGridModule";
import { TodoBoardModule } from "../module/modules/TodoBoardModule/TodoBoardModule";
import { TAppData } from "./AppContext";
import { TData } from "./interface";

export const scheduleActivity = async (dataContext: TData, appContext: TAppData, scheduledActivity: ScheduledActivity, user, date?: string) => {
  dataContext.scheduledActivities.addScheduledActivity(scheduledActivity)

  if (date) {
    const calendarData = await dataContext.calendarData.getCalendarDate(date, true, false);
    calendarData.addScheduledActivity(scheduledActivity);
    dataContext.calendarData.setCalendarDate(calendarData, true);
    calendarData.updateFirebase(user);
  }
  else {
    const todoBoardModule: TodoBoardModule = await getTodoModule(dataContext, appContext)
    todoBoardModule.createBoardItem(scheduledActivity.content, scheduledActivity.activityId);
  }

  scheduledActivity.updateFirebase(user);
}

export const getRootLayout = async (dataContext: TData, appContext: TAppData) => await dataContext.modules.getModule("LayoutStartPoint", dataContext, appContext, true) as ReactGridModule;
export const getTodoModule = async (dataContext: TData, appContext: TAppData): Promise<TodoBoardModule> => await dataContext.modules.getModule("todoBoardModule", dataContext, appContext, true) as TodoBoardModule;

export const addModuleToRootLayout = async (dataContext: TData, appContext: TAppData, module: ModuleRelations, updateFirebase?: boolean) => {
  const rootLayout = await getRootLayout(dataContext, appContext);
  // rootLayout.addModule(module);
  rootLayout.addChildModule(module, rootLayout);
  if (updateFirebase) {
    rootLayout.updateFirebase();
    module.updateFirebase();
    rootLayout.forceRender();
  }
}

export const removeModule = (moduleToBeRemoved: ModuleRelations) => {
  moduleToBeRemoved.closeModal();
  if ((moduleToBeRemoved?.parentModule as any)?.showCardContainer) {
    moduleToBeRemoved.parentModule.closeModal();
    (moduleToBeRemoved.parentModule as any).showCardContainer()
  }
  moduleToBeRemoved.parentModule.removeChildModule(moduleToBeRemoved, moduleToBeRemoved.parentModule);
  moduleToBeRemoved.parentModule.forceRender();
}

export const getModuleChildren = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData): Promise<BaseModule[]> => {
  const props: any = (baseProps as any);
  const nestedIds = props.layout && props.layout[appContext.currentBreakpoint] ? Object.keys(props.layout[appContext.currentBreakpoint]) : [];
  const childModules: BaseModule[] = nestedIds ? await dataContext.modules.getModules(nestedIds, dataContext, appContext, true) : [];
  return childModules
}

export const removeModuleFromRootLayout = async (dataContext: TData, appContext: TAppData, module: ModuleRelations, updateFirebase?: boolean) => {
  const rootLayout = await getRootLayout(dataContext, appContext);
  rootLayout.removeChildModule(module, rootLayout);
  if (updateFirebase) {
    rootLayout.updateFirebase();
  }
}

export const clearObjectAndNestedObjectAndArraysOfUndefines = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(clearObjectAndNestedObjectAndArraysOfUndefines).filter(value => value !== undefined);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const cleanedValue = clearObjectAndNestedObjectAndArraysOfUndefines(obj[key]);
      if (cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  }
  return obj;
}