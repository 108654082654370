import * as React from 'react';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

const FootballIcon = ({ onClick = null, className = "" }) => {
    return <SportsSoccerIcon className={`football-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { FootballIcon };
