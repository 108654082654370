import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

const ActivityIcon = ({ onClick = null, className = "" }) => {
    return <SettingsIcon className={`activity-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { ActivityIcon };
