import * as React from 'react';
import {BabyChangingStation} from '@mui/icons-material';

const VabIcon = ({ onClick = null, className = "" }) => {
    return <BabyChangingStation className={`vab-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { VabIcon };
