import * as React from 'react';
import {Settings} from '@mui/icons-material';

const SIcon = ({ onClick = null, className = "" }) => {
    return <Settings className={`settings-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { SIcon as SettingsIcon };
