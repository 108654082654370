import { TData } from "../../../app/interface";
import { breakpointTypes, ILayouts, modules } from "../../../utils/constants";
import { Layout } from "../../Layout";
import { BaseModule, IBaseModule } from "../BaseModule";

export interface IModuleRelationsProps {
    parentModule: ModuleRelations;
    childModules: BaseModule[];
    layout: Layout;
    onCreateChildModule?: (createdModule: ModuleRelations, parentModule: ModuleRelations) => void;
    onRemoveChildModule?: (removedModule?: ModuleRelations, parentModule?: ModuleRelations) => void;
}

class ModuleRelations extends BaseModule implements IModuleRelationsProps {
    parentModule: ModuleRelations;
    childModules: BaseModule[];
    layout: Layout;
    onCreateChildModule?: (createdModule: ModuleRelations, parentModule: ModuleRelations) => void;
    onRemoveChildModule?: (removedModule?: ModuleRelations, parentModule?: ModuleRelations) => void;

    constructor(baseProps: IBaseModule, moduleRelationProps: IModuleRelationsProps/*, childModules: BaseModule[], layout?: Layout */) {
        super(baseProps);
        Object.assign(this, moduleRelationProps);
    }

    getCurrentBreakpoint() {
        const rootParentModule = this.getRootParentModule();
        if (rootParentModule.currentBreakpoint)
            return rootParentModule.currentBreakpoint;
        else
            return ""
    }

    getRootParentModule = () => {
        const parentModule = this.parentModule;
        if (!parentModule) {
            return this;
        }
        else {
            return parentModule.getRootParentModule();
        }
    }

    removeChildModule = (module: ModuleRelations, parentModule: ModuleRelations) => {
        if (this.id === parentModule.id) {
            this.layout?.removeGrid(module.id);
            this.childModules = this.childModules.filter(m => m.id !== module.id);
            if (this.onRemoveChildModule) {
                this.onRemoveChildModule(module, parentModule);
            } else {
                this.parentModule.removeChildModule(module, parentModule);
            }
        }
        else {
            if (this.onRemoveChildModule) {
                this.onRemoveChildModule(module, parentModule);
            }
            else if (this.parentModule) {
                this.parentModule.removeChildModule(module, parentModule);
            }
        }

        return module;
    }

    addChildModule = (module: ModuleRelations, parentModule: ModuleRelations, grid?: ILayouts) => {
        module.parentModule = parentModule;
        if (this.id !== parentModule.id && this.parentModule) {
            this.parentModule.addChildModule(module, parentModule, grid);
        }
        else if (this.id === parentModule.id) {
            if (!this.layout) {
                this.layout = new Layout({ id: this.id, [breakpointTypes.SM]: {}, [breakpointTypes.LG]: {} });
            }
            if (!this.childModules) {
                this.childModules = []
            }
            this.layout.addGrid(grid || module.getDefaultGrid());
            this.childModules.push(module)
            if (this.onCreateChildModule) {
                this.onCreateChildModule(module, parentModule);
            } else if (this.parentModule) {
                this.parentModule.addChildModule(module, parentModule, grid);
            }
            else {
                console.log("no onCreateChildModule was called");
            }
            // this.closeModal();
        }
        else if (!this.onCreateChildModule) {
            if (this.parentModule) {
                this.parentModule.addChildModule(module, parentModule, grid);
            }
            else if(this.onCreateChildModule) {
                this.onCreateChildModule(module, parentModule);
            }
        }
        else if (this.onCreateChildModule) {
            this.onCreateChildModule(module, parentModule);
        }
        return module;
    }

    getCreationDialog(onCreate: (module: BaseModule) => void): JSX.Element {
        return null;
        // return null;
    }

    renderTopMenu(dataContext: TData): JSX.Element {
        return null;
    }

    render(dataContext: TData): JSX.Element {
        return null;
    }

    toFirebaseObject() {
        return null;
    }

    static getDefaultModuleRelationsProps = (customRelationProps?: Partial<IModuleRelationsProps>): IModuleRelationsProps => {
        return {
            parentModule: null,
            childModules: [],
            layout: null,
            onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
            onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => { },
            ...customRelationProps
        }
    }
}

export { ModuleRelations }