import * as React from 'react'
import { Button } from '../../../components/button/Button'
import { Row } from '../../../components/layout/row/Row'

const ActivityRow = ({ activity, /* onBackClick, onForwardClick, */ onSelect }) => {
  return (
    <Row className="activity-row margin-bottom" dataTestid="activity-row">
      {/* {activity.parentActivities?.length > 0 && <ArrowBackIcon onClick={() => onBackClick(activity)} />} */}
      <Button label={activity.name} onClick={() => {
        onSelect(activity)
      }} />
      {/* {activity.childActivities?.length > 0 && <ArrowForwardIcon onClick={() => onForwardClick(activity)} />} */}
    </Row>
  )
}

export { ActivityRow }