import * as React from 'react'

const TextArea = ({ value = "", onChange, className = undefined, rows = undefined, cols = undefined }) => {
    const [text, setText] = React.useState(value);

    return (
        <textarea
            className={className}
            rows={rows}
            cols={cols}
            value={text}
            onChange={(e) => {
                setText(e.target.value);
                if (onChange) {
                    onChange(e.target.value)
                }
            }}></textarea>
    )
}

export { TextArea }