import * as React from 'react';
import {ThumbUpOffAlt} from '@mui/icons-material/';

const ThumbsUpIcon = ({ onClick = null, className = "" }) => {
    return <ThumbUpOffAlt className={`thumb-up-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { ThumbsUpIcon };
