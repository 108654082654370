import * as React from 'react'
import { useState } from 'react'
import { Checkbox as MaterialCheckbox } from "@mui/material";

interface ICheckbox {
    checked?: boolean;
    onChange?: (checked: boolean, label?: string, e?: any) => void;
    canChange?: boolean;
    className?: string;
    label?: string;
    renderLabelRight?: boolean;
    textColor?: string;
    style?: any
    testId?: string;
}

const Checkbox = ({ checked = false, onChange, canChange = true, label, className, renderLabelRight = false, textColor = null, style = null, testId }: ICheckbox) => {
    const [isChecked, setIsChecked] = useState(checked);

    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const onC = (e) => {
        if (canChange === true) {
            const newChecked = !isChecked;
            setIsChecked(newChecked);
            if (onChange) {
                onChange(newChecked, label, e);
            }
        }
    }

    return <div data-testid={testId} onClick={onC}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', color: textColor, ...style }}>
        {!renderLabelRight && label}
        <MaterialCheckbox className={className} checked={isChecked}/*  onChange={onC} */ />
        {renderLabelRight && label}
    </div>

}

export { Checkbox }
