import * as React from 'react'
import { Fragment, useState } from 'react'
import { Tag } from './Tag';
import "./tags.scss"
import { generateId } from '../../utils/Utils';
import InputConfirm from '../input_and_confirm/InputConfirm';
import { Row } from '../layout/row/Row';
import Modal from '../modal/Modal';
import { PlusIcon } from '../../icons/PlusIcon';
import { Label } from '../label/Label';
import { DataContext } from '../../app/DataContext';
import { CloseIcon } from '../../icons/CloseIcon';
import { Column } from '../layout/column/Column';
import { LogTypeEnums } from '../../utils/constants';

export interface ITag {
    id: string;
    name: string;
}

const Tags = ({ onClose = undefined, hideCreateTag = false, initialSelectedTags = [], selectable = true, onChange = null, moduleid = "" }) => {

    const dataContext = React.useContext(DataContext)
    const [selectedTags, setSelectedTags] = useState<ITag[]>(initialSelectedTags)
    const [showCreateTag, setShowCreateTag] = React.useState(false);
    const [prioedTags, setPrioritizedTags] = React.useState<{name: string, occurrences: number}[]>();

    React.useEffect(() => {
        dataContext.tags.getPrioedTags(dataContext).then(setPrioritizedTags);
    }, [])

    React.useEffect(() => {
        if (moduleid && prioedTags) {
            // loadModuleTags()
        }
    }, [moduleid, prioedTags]);

    // const loadModuleTags = async () => {
    //     const module = await dataContext.modules.getModule(moduleid, dataContext)
    //     const tags = await module.getTags(dataContext);
    //     setSelectedTags(tags);
    // }

    const createTag = async name => {
        dataContext.tags.addTag({ id: generateId(), name })
        setShowCreateTag(false);
    }

    const renderCreateTags = () => <Row>
        <PlusIcon onClick={() => setShowCreateTag(!showCreateTag)} />
        <Label text="Skapa tagg" />
    </Row>

    const renderTags = () => {
        const allTags = Object.values(dataContext.tags.tags);
        const sortedTags = allTags?.length > 0 ? allTags.sort((a, b) => {
            const aValue = prioedTags.find(t => t.name === b.name)?.occurrences || 0
            const bValue = prioedTags.find(t => t.name === a.name)?.occurrences || 0
            return aValue > bValue ? 1 : -1;
        }) : [];
        return <div className="tags">
            {sortedTags?.map((tag, index) => {
                const isSelected = selectedTags.some(s => s.id === tag.id);
                return <Fragment key={index}>
                    <Tag tag={tag} selectable={selectable} selected={isSelected} onRemove={() => {
                        dataContext.tags.removeTag(tag);
                    }}
                        onClick={async (isSelected) => {
                            if (isSelected) {
                                selectedTags.push(tag);
                                setSelectedTags([...selectedTags])
                                if (onChange) {
                                    onChange(selectedTags);
                                }
                            }
                            else {
                                const filteredTags = selectedTags.filter(t => t.id !== tag.id)
                                if (onChange) {
                                    onChange(filteredTags);
                                }
                                setSelectedTags(filteredTags);
                            }
                            await dataContext.calendarData.createEventLog("clicked tag", tag.name, LogTypeEnums.TAG, true);
                            // const prioedTags = await dataContext.tags.getPrioedTags(dataContext);
                            // setPrioritizedTags(prioedTags);
                            // console.log(prioedTags);
                        }} /></Fragment>
            })}
        </div>
    }
    return (
        <div>
            <Column alignLeft>
                {onClose && <CloseIcon dataTestid="tagsCloseIcon" onClick={onClose} />}
                {!hideCreateTag && renderCreateTags()}
                <Modal showModal={showCreateTag} setShowModal={setShowCreateTag}>
                    <InputConfirm label='Skapa tagg' className="tag-name" onConfirm={val => {
                        if (val) {
                            createTag(val)
                        }
                    }} />
                </Modal>
                {prioedTags && renderTags()}
            </Column>
        </div>
    )
}

export { Tags }