import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Column } from '../../layout/column/Column';
import { DatePicker } from '../datepicker/DatePicker';
import { TimePicker } from '../time_picker/TimePicker';
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { convertTimestampToTimepickerStringTime } from '../../../utils/TimeUtils';

//https://mui.com/x/api/date-pickers/date-time-picker/

interface IDateTimepicker {
    onChange: (date: Date) => void;
    defaultTime?: Date;
    separateTimeAndDate?: boolean;
    dataTestid?: string;
}

const dateToTimeArray = (date) => {
    const time = convertTimestampToTimepickerStringTime(date.getTime())
    const hour = time.split(":")[0]
    const minutes = time.split(":")[1]
    return [parseInt(hour), parseInt(minutes)];
}

const setTimeToDate = (date, timeArray) => {
    const d = new Date(date)
    d.setHours(timeArray[0]);
    d.setMinutes(timeArray[1]);
    return d;
}

export const DateTimepicker = ({ onChange, defaultTime = new Date(), separateTimeAndDate = false, dataTestid = undefined }: IDateTimepicker) => {
    const [selectedDate, setSelectedDate] = React.useState<Date>(defaultTime);
    const [selectedTime, setSelectedTime] = React.useState<number[]>(dateToTimeArray(selectedDate));
    const [value, setValue] = React.useState<Date | null>(setTimeToDate(selectedDate, selectedTime));

    if (!separateTimeAndDate) {
        return (
            <div data-testid={dataTestid}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    ampm={false}
                    renderInput={(props) => <TextField {...props} />}
                    label="DateTimePicker"
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                        onChange(newValue);
                    }}
                />
            </LocalizationProvider>
            </div>
        );
    }

    return <Column dataTestid={dataTestid || "datetime-picker"} alignLeft>
        <DatePicker defaultTime={value.getTime()} onChange={date => {
            const d = setTimeToDate(date, selectedTime)
            setSelectedDate(d)
            setValue(d);
            onChange(d);
        }} />
        <MarginBottom />
        <TimePicker defaultTime={value.getTime()} onChange={date => {
            const timeArray = dateToTimeArray(date);
            const d = setTimeToDate(selectedDate, timeArray)
            setSelectedTime(timeArray)
            setValue(d);
            onChange(d);
        }} />
        <div className="margin-bottom" />
    </Column>
}