import * as React from 'react';
import { Lock } from '@mui/icons-material';

const LockIcon = ({ onClick = null, className = "" }) => {
    return <Lock className={`lock-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { LockIcon };
