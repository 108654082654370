import * as React from 'react';
import ChIcon from '@mui/icons-material/Check';

interface ICheckIcon {
    onClick?: Function;
    color?: string;
    className?: any;
}

const CheckIcon = ({ onClick = null, color = undefined, className = "" }: ICheckIcon) => {
    return <ChIcon style={color ? { color } : undefined} className={`check-icon${className ? " " + className : ""}`} onClick={() => {
        if (onClick) { onClick() }
    }} />
};

export { CheckIcon };
