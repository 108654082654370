import * as React from 'react'
import { CalendarCard } from './CalendarCard';
import { subDays, addDays, eachDayOfInterval, getMonth } from 'date-fns'
import { getMonthName, weekdays } from '../../utils/constants';
import { AppContext } from '../../app/AppContext';

export const getWeekNumber = (week) => {
    const firstDayOfWeek = week[0];
    const date = new Date(firstDayOfWeek.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    const weekNumber = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
    return weekNumber;
}

export const getWholeWeek = (week, isFirstWeek) => {
    const extraDays = 7 - week.length;
    if (extraDays > 0 && isFirstWeek) {
        const start = subDays(week[0], extraDays)
        const end = subDays(week[0], 1);
        const extraDates = eachDayOfInterval({ start, end })
        week.unshift(...extraDates);
    }
    if (extraDays > 0 && !isFirstWeek) {
        const start = addDays(week[week.length - 1], 1);
        const end = addDays(week[week.length - 1], extraDays)
        const extraDates = eachDayOfInterval({ start, end })
        week = week.concat(extraDates);
    }
    return week;
}

const CalendarWeek = ({ month, week, isFirstWeek }) => {
    const { isMobile } = React.useContext(AppContext)

    week = getWholeWeek(week, isFirstWeek);
    const renderWeekDayLabels = (weekdayIndex) => {
        return <div className="calendar-week-day-labels">
            <div className="week-day-label">{weekdays[weekdayIndex]}</div>
        </div>
    }

    return (
        <div className={`calendar-week-days`}>
            {week?.length > 0 && week.map((date, index) => {
                const greyedOut = date.getMonth() !== month;
                const monthName = getMonthName(getMonth(date))
                return <div className="calendar-day" key={index}>
                    {/* {!isMobile && isFirstWeek && renderWeekDayLabels(index)} */}
                    <CalendarCard date={date} greyedOut={greyedOut} monthLabel={monthName} weekdayLabel={weekdays[index]}></CalendarCard>
                </div>
            })}
        </div>
    )
}

export { CalendarWeek }
