import React from 'react'
import { Button } from '../../components/button/Button';
import "./Test.scss";
import { createStartkitFirebase, IStartkit, Signup } from '../../login/Signup';
import { Column } from '../../components/layout/column/Column';
import { Login } from '../../login/Login';
import { MarginBottom } from '../../components/layout/margin/MarginBottom';
import { Row } from '../../components/layout/row/Row';
import { MarginRight } from '../../components/layout/margin/MarginRight';
import { useNavigate } from 'react-router-dom';

export const Home = () => {

    const [showLogin, setShowLogin] = React.useState(true);
    const [showCreateAccount, setShowCreateAccount] = React.useState(false);
    let navigate = useNavigate();

    React.useEffect(() => {
        // init();
    }, [])

    // const init = async () => {
    //     let switchWord = searchParams.get("mock")
    //     switchWord = appContext.mock ? "activity" : switchWord;
    //     switch (switchWord) {
    //         // case "custom":
    //         //     await standardMock(appContext, dataContext, false, async (dataContext: TData) => {
    //         //         // const startTime = subHours(new Date().getTime(), 1).getTime();
    //         //         // dataContext.scheduledActivities.createScheduledActivity("Test", dataContext, false, undefined, undefined, subHours(new Date().getTime(), 3).getTime())
    //         //     });
    //         //     // await createActivity("Testactivity1", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     // await createActivity("Testactivity2", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     // await createFourCards(dataContext);
    //         //     // await createUpcomingActivities(dataContext);
    //         //     // await addUpcomingActivity(dataContext, "Testactivity1");
    //         //     // const todoBoard = await createTodoBoard(dataContext, { x: 6, y: 6, w: 6, h: 20 }) as TodoBoardModule;
    //         //     // await todoBoard.addScheduledActivityCard("TestKort1", dataContext, undefined, undefined);
    //         //     await createActivityTable(dataContext, { x: 12, y: 6, w: 6, h: 20 }, "5");
    //         //     // await createCalendar(dataContext);
    //         //     break;
    //         // case "empty":
    //         //     await standardMock(appContext, dataContext);
    //         //     break;
    //         // case "standard":
    //         //     await standardMock(appContext, dataContext);
    //         //     // await createFourCards(dataContext);
    //         //     // await generateNestedCard(dataContext, "Nästlat kort 1", "5", 5, "4");
    //         //     break;
    //         // case "board":
    //         //     await standardMock(appContext, dataContext,);
    //         //     // await createFourCards(dataContext);
    //         //     dataContext.tags.addTag({ id: "1", name: "TestTag1" });
    //         //     dataContext.tags.addTag({ id: "2", name: "TestTag2" });
    //         //     // const boardModule: BoardModule = await createBoard(dataContext, "TestBoard7", { ...getGrid(4, "7"), h: 20 }, "7") as BoardModule
    //         //     // await boardModule.addCard("TestKort1", dataContext);
    //         //     // await boardModule.addCard("TestKort2", dataContext, false, false, undefined, undefined,);
    //         //     // await boardModule.addCard("TestKort3", dataContext);
    //         //     break;
    //         // case "activity":
    //         //     await standardMock(appContext, dataContext, false, async (dataContext: TData) => {
    //         //         // const startTime = subHours(new Date().getTime(), 1).getTime();
    //         //         // dataContext.scheduledActivities.createScheduledActivity("Test", dataContext, false, undefined, undefined, startTime)
    //         //     });
    //         //     // await createFourCards(dataContext);
    //         //     await createUpcomingActivities(dataContext);
    //         //     // await addUpcomingActivity(dataContext, "Testactivity1");
    //         //     await createTodoBoard(dataContext, { x: 6, y: 6, w: 6, h: 20 }) as TodoBoardModule;
    //         //     await createActivityTable(dataContext, { x: 12, y: 6, w: 6, h: 20 }, "5");
    //         //     await createCalendar(dataContext);
    //         //     // await createActivity("Testactivity1", dataContext);
    //         //     break;
    //         // case "scheduling":
    //         //     await standardMock(appContext, dataContext, false, async (dataContext: TData) => {
    //         //         const startTime = subHours(new Date().getTime(), 1).getTime();
    //         //         dataContext.scheduledActivities.createScheduledActivity("Test", dataContext, false, undefined, undefined, startTime)
    //         //     });
    //         //     await createActivity("Testactivity1", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     await createActivity("Testactivity2", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     // await createFourCards(dataContext);
    //         //     await createUpcomingActivities(dataContext);
    //         //     // await addUpcomingActivity(dataContext, "Testactivity1");
    //         //     await createTodoBoard(dataContext, { x: 6, y: 6, w: 6, h: 20 }) as TodoBoardModule;
    //         //     await createActivityTable(dataContext, { x: 12, y: 6, w: 6, h: 20 }, "5");
    //         //     await createCalendar(dataContext);
    //         //     break;
    //         // case "upcoming":
    //         //     await standardMock(appContext, dataContext, false);
    //         //     await createUpcomingActivities(dataContext);
    //         //     await scheduleActivities(dataContext);
    //         //     break;
    //         // case "datedialog":
    //         //     await standardMock(appContext, dataContext);
    //         //     await createUpcomingActivities(dataContext);
    //         //     break;
    //         // case "autoschedule":
    //         //     await standardMock(appContext, dataContext);
    //         //     await createActivityTable(dataContext, { x: 6, y: 6, w: 6, h: 20 }, "5");
    //         //     await createUpcomingActivities(dataContext);
    //         //     await createActivity("Testactivity1", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     await createActivity("Testactivity2", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     await createActivity("Testactivity3", dataContext, { interval: (1000 * 60 * 60 * 24 * 2) });
    //         //     // dataContext.scheduledActivities.createScheduledActivity(getToday(), TestActivity.name, dataContext, true, new Date().getTime() + 60000, TestActivity.id);
    //         //     break;
    //         // case "todo":
    //         //     await standardMock(appContext, dataContext, false, () => {});
    //         //     const todoBoardModule = await createTodoBoard(dataContext, { x: 6, y: 6, w: 6, h: 30 }) as TodoBoardModule;
    //         //     dataContext.tags.addTag({ id: "1", name: "TestTag1" });
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort1", dataContext, undefined, undefined, [{id: "1", name: "TestTag1"}]);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort2", dataContext, undefined, undefined, [{id: "1", name: "TestTag1"}]);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort3", dataContext, undefined, undefined, [{id: "1", name: "TestTag1"}]);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort4", dataContext, undefined, undefined);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort5", dataContext, undefined, undefined);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort6", dataContext, undefined, undefined);
    //         //     // todoBoardModule.addScheduledActivityCard("TestKort7", dataContext, undefined, undefined);
    //         //     break;
    //         // default:
    //         //     break;
    //     }
    // }

    const LoginButton = () => <Button label={'Logga in'} onClick={() => {
        setShowLogin(true);
        setShowCreateAccount(false)
    }} />

    const SignupButton = () => <Button label={'Skapa konto'} onClick={() => {
        setShowCreateAccount(true);
        setShowLogin(false);
    }} />

    // const LoginTestUserButton = () => <Button label={'Logga in som testanvändare'} onClick={async () => {
    //     await standardMock(appContext, dataContext);
    //     // await createFourCards(dataContext);
    // }} />;
    return (
        <>
            {showLogin && <Column>
                <Login />
                <MarginBottom />
                <SignupButton />
            </Column>}
            {showCreateAccount && <Column>
                <Signup defaultName="TestUsername" defaultEmail="test@test.test" defaultPassword='test123' onCreateUser={(startkit: IStartkit) => {
                    createStartkitFirebase(startkit);
                    navigate(`/home`);
                }} />
                <MarginBottom />
                <LoginButton />
            </Column>}
            {!showLogin && !showCreateAccount && <Row>
                <LoginButton />
                <MarginRight />
                <SignupButton />
            </Row>
            }
            <MarginRight />
            {/* <LoginTestUserButton /> */}
        </>
    )
}
