import { getNow } from "../../utils/TimeUtils";
import { clearObjectOfUndefines, generateId } from "../../utils/Utils";
import { Activity } from "./Activity";

export class Program {
    id: string;
    name: string;
    excercises: Excercise[];

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.excercises = props.excercises
    }
}

export class Excercise {
    id: string;
    name: string;
    reps: number;
    weight: number;
    time?: number;
    timestamp?: number;
    finished?: boolean;
    hideCompletely?: boolean;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.reps = parseInt(props.reps);
        this.weight = parseInt(props.weight);
        this.time = props.time ? parseInt(props.time) : 0;
        this.timestamp = props.timestamp || 0;
        this.finished = props.finished || false;
    }
}

export class GymActivity extends Activity {
    isGym: boolean;
    program: Program;
    history: { [timestamp: number]: Excercise[] };

    constructor(props) {
        super(props);
        this.program = new Program({ id: props.program?.id || generateId(), name: props.program?.name || "", excercises: props.program?.excercises?.length ? props.program.excercises.map(excercise => new Excercise(excercise)) : [] }) //excercises?.map(e => new Program(e)) || [];
        // this.program.excercises = this.program?.excercises?.length > 0 ? this.program.excercises.map(excercise => new Excercise(excercise)) : []
        this.history = props.history;
        this.isGym = true;
    }

    addNewExcercise = (newExcercise: Excercise, user: string) => {
        if (!this.program) {
            this.program = new Program({ id: generateId(), name: "", excercises: [] });
        }
        if (!this.program.excercises) {
            this.program.excercises = [];
        }
        this.program.excercises.push(newExcercise);
        this.updateFirebase(user);
    }

    removeExcercise = (excercise: Excercise, user: string) => {
        this.program.excercises = this.program.excercises.filter(ex => excercise.id !== ex.id);
        this.updateFirebase(user);
    }

    convertToFirebaseObject() {
        const obj: any = super.convertToFirebaseObject();
        this.program.excercises = this.program.excercises.map((excercise: Excercise) => {
            clearObjectOfUndefines(excercise);
            return excercise;
        })
        console.log(this.program.excercises[0]);
        delete obj.addNewExcercise;
        delete obj.removeExcercise;
        return obj;
    }

    addHistory(excercises: Excercise[], user: string) {
        if (!this.history) {
            this.history = {}
        }

        this.history[getNow()] = excercises.map((excercise) => {
            clearObjectOfUndefines(excercise);
            return excercise;
        })
        this.updateFirebase(user);
    }

}