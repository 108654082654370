import * as React from 'react'
import './Signup.scss';
import { createUser, writeData } from '../utils/Firebase';
import { Activity } from '../data/activity/Activity';
import { reactGridLayoutMockNoModules, createActivityTableModule } from '../data/mock/MockData';
import { ActivityTableModule } from '../module/modules/ActivityTableModule/ActivityTableModule';
import { CalendarEventsModule } from '../module/modules/CalendarEventsModule/CalendarEventsModule';
import { CalendarModule } from '../module/modules/CalendarModule/CalendarModule';
import { ReactGridModule } from '../module/modules/ReactGridModule/ReactGridModule';
import { TodoBoardModule } from '../module/modules/TodoBoardModule/TodoBoardModule';
import { GET_USER, modules } from '../utils/constants';
import { getNow } from '../utils/TimeUtils';

export interface IStartkit {
    userId: string;
    userName: string;
    activities: Activity[],
    rootLayoutModule: ReactGridModule,
    // upcomingModule: CalendarEventsModule,
    todoBoardModule: TodoBoardModule,
    calendarModule: CalendarModule,
    activityTableModule: ActivityTableModule
}

const createFirebaseProfile = async (userId, username) => {
    writeData(`users/${userId}/profile/`, { id: userId, name: username });
    writeData(`${GET_USER(userId)}/${username}`, "dummy data")
}

export const createStartKit = (user, userName): IStartkit => {

    const hushallActivity: Activity = new Activity({ name: "Hushåll", id: "hushall", created: getNow() });
    const fritidActivity: Activity = new Activity({ name: "Fritid", id: "fritid", created: getNow() });

    const rootLayoutModule: ReactGridModule = reactGridLayoutMockNoModules(
        { id: "LayoutStartPoint", name: "Mainlayout", user },
        ["upcomingEventsModule", "todoBoardModule", "activityTableModule", "calendarModule"],
        [{ w: 4, h: 20, x: 0 }, { w: 4, x: 4, h: 20 }, { w: 4, x: 8, h: 20 }, { w: 12, x: 0, y: 20, h: 25 }]
    )
    // const upcomingModule: CalendarEventsModule = createUpcomingModule({ id: "upcomingEventsModule", name: "UpcomingEventsModule", user })
    const todoBoardModule: TodoBoardModule = TodoBoardModule.createTodoBoardModule({ id: "todoBoardModule", name: "TodoBoardModule", user })
    const calendarModule: CalendarModule = new CalendarModule({ id: "calendarModule", type: modules.CALENDAR, user, name: "CalendarModule", onDelete: (self) => {console.log(self)} })
    const activityTableModule: ActivityTableModule = createActivityTableModule({ id: "activityTableModule", name: "ActivityTableModule", user }/* , {
        initialData: [
            {
                activity: hushallActivity,
                scheduledActivities: []
            }
        ]
    } */)

    return { userId: user, userName, activities: [hushallActivity, fritidActivity], rootLayoutModule, todoBoardModule, calendarModule, activityTableModule }
}

export const createStartkitFirebase = (startkit: IStartkit) => {
    const { userId, userName, activities, rootLayoutModule, /* upcomingModule, */ todoBoardModule, calendarModule, activityTableModule } = startkit;
    console.log(userName);
    createFirebaseProfile(userId, userName);
    activities.forEach(activity => activity.updateFirebase(userId));

    rootLayoutModule.updateFirebase()
    // upcomingModule.updateModuleFirebase()
    todoBoardModule.updateFirebase()
    activityTableModule.updateModuleFirebase()
    calendarModule.updateModuleFirebase();
}

export const Signup = ({ onCreateUser, defaultName, defaultEmail, defaultPassword, }: { defaultName?: string, defaultEmail?: string, defaultPassword?: string, onCreateUser: (startkit: IStartkit) => void }) => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const { username, email, password } = event.target.elements;
        try {
            const user = await createUser(email.value, password.value)
            const startkit: IStartkit = createStartKit(user, username.value);
            createFirebaseProfile(user, username.value);
            onCreateUser(startkit);
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div className="signup">
            <h1>Sign up</h1>
            <form className="signup__form" onSubmit={onSubmit}>
                <label className="margin-bottom">
                    Användarnamn{" "}
                    <input data-testid="user-signup" defaultValue={defaultName} name="username" type="username" placeholder="Username" />
                </label>
                <label className="margin-bottom">
                    Email{" "}
                    <input data-testid="email-signup" defaultValue={defaultEmail} name="email" type="email" placeholder="Email" />
                </label>
                <label className="margin-bottom">
                    Lösenord{" "}
                    <input data-testid="pass-signup" defaultValue={defaultPassword} name="password" type="password" placeholder="Password" />
                </label>
                <button data-testid="confirm-signup" type="submit">Sign up</button>
            </form>
        </div>
    )
}
