import * as React from 'react'
import { useState } from 'react'
import { DeleteIcon } from '../../icons/DeleteIcon';
import "./tags.scss"

const Tag = ({ tag, selected = false, selectable, onClick, onRemove }) => {

    const [isSelected, setIsSelected] = useState(false);

    React.useEffect(() => {
        setIsSelected(selected);
    }, [selected])

    return (
        <div className={`tag${isSelected ? " selected" : ""}`} onClick={() => {
            if (selectable) {
                const changedSelected = !isSelected

                setIsSelected(changedSelected);
                onClick(changedSelected)
            }
        }}>{tag.name}<DeleteIcon onClick={() => onRemove(tag)} /></div>
    )
}

export { Tag }