import { Button } from '../../components/button/Button';
import { Input } from '../../components/input/Input';
import * as React from 'react'
import { useState } from 'react'
import "./InputConfirm.scss"
import classNames from 'classnames';

interface IProps {
    label?: string;
    defaultValue?: string;
    onConfirm?: Function;
    onChange?: Function;
    buttonLabel?: string
    className?: string
    small?: boolean;
    hideConfirmButton?: boolean
    okButtonTestid?: string
    testId?: string;
}
const InputConfirm = ({ label = "", onConfirm, defaultValue = "", onChange, buttonLabel = "OK", className, small, hideConfirmButton = false, okButtonTestid = "", testId = "" }: IProps) => {

    const [input, setInput] = useState<string>();

    React.useEffect(() => {
        if (defaultValue) {
            setInput(defaultValue);
        }
    }, [defaultValue])

    const onChangeFunction = value => {
        if (onChange) {
            onChange(value);
        }
        setInput(value)
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            // onConfirm(input);
            setInput("")
            onConfirm(input, e);
        }
    }

    return <div
        date-testid={testId}
        className={`input-confirm${className ? " " + className : ""}`}>
        <Input
            dataTestid={classNames(
                testId ? `${testId}-input` : ""
            )}
            label={label}
            value={input}
            className={
                classNames(
                    "margin-bottom",
                    className
                )
            }
            onKeyDown={onKeyDown}
            small
            onChange={value => {
                setInput(value);
                onChangeFunction(value)
            }} />
        {!hideConfirmButton && <Button
            dataTestid={`${testId}-button`}
            className={classNames(
                "input-confirm-button",
                className && `${className}-input-confirm-button`
            )}
            label={buttonLabel}
            onClick={(e) => {
                setInput("")
                onConfirm(input, e.target.value)
            }} />}
    </div>
}

export default InputConfirm;